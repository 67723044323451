import React, { useState } from "react";
import { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Row,
  Col,
} from "reactstrap";
import industries from "./industryList.js";
import states from "./statesList.js";
import moment from "moment";
import styles from "./OnBoardTenant.module.css";
import { Notify } from "../../helpers/notification.js";
import Select from "react-select";
import { useTranslation } from "react-i18next";


const EmploymentHistory = (props) => {
  const [showEmploymentOptionModal, setShowEmploymentOptionModal] =
    useState(false);
  const [showEmploymentFormModal, setShowEmploymentFormModal] = useState(false);
  const [showGOPFormModal, setShowGOPFormModal] = useState(false);
  const [selectedEmployment, setSelectedEmployment] = useState(undefined);
  const [employmentFormErrors, setEmploymentFormErrors] = useState({});
  const [GOPFormErrors, setGOPFormErrors] = useState({});
  const [employments, setEmployments] = useState([]);
  const [enableNext, setEnableNext] = useState(false);
  const { form, setForm, loading } = props;
  const {t} = useTranslation();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    if (form?.employments) {
      setEmployments(form?.employments);
    }
  }, [form?.employments]);

  useEffect(() => {
    const temp = validations(false);
    if (temp) {
      setEnableNext(true);
    }
  }, [employments]);

  const validateEmploymentFormAndSubmit = () => {
    if (
      selectedEmployment &&
      selectedEmployment["LobbyQueue_NHHS::HistoryEmploymentType"] == "W2"
    ) {
      const tempEmploymentFormError = { ...employmentFormErrors };

      if (!selectedEmployment["LobbyQueue_NHHS::HistoryEmployer"]) {
        tempEmploymentFormError["LobbyQueue_NHHS::HistoryEmployer"] =
          "Company name is required";
      } else {
        delete tempEmploymentFormError["LobbyQueue_NHHS::HistoryEmployer"];
      }

      if (!selectedEmployment["LobbyQueue_NHHS::HistorySupervisor"]) {
        tempEmploymentFormError["LobbyQueue_NHHS::HistorySupervisor"] =
          "Manage/Supervisor name is required";
      } else {
        delete tempEmploymentFormError["LobbyQueue_NHHS::HistorySupervisor"];
      }

      if (!selectedEmployment["LobbyQueue_NHHS::HistoryEmployerPhone"]) {
        tempEmploymentFormError["LobbyQueue_NHHS::HistoryEmployerPhone"] =
          "Company phone is required";
      } else if (
        selectedEmployment["LobbyQueue_NHHS::HistoryEmployerPhone"]
          ?.replaceAll("(", "")
          ?.replaceAll(")", "")?.length < 10
      ) {
        tempEmploymentFormError["LobbyQueue_NHHS::HistoryEmployerPhone"] =
          "Enter a valid phone number";
      } else {
        delete tempEmploymentFormError["LobbyQueue_NHHS::HistoryEmployerPhone"];
      }

      if (
        !selectedEmployment["LobbyQueue_NHHS::HistoryAddressEmployer_Address"]
      ) {
        tempEmploymentFormError[
          "LobbyQueue_NHHS::HistoryAddressEmployer_Address"
        ] = "Address is required";
      } else {
        delete tempEmploymentFormError[
          "LobbyQueue_NHHS::HistoryAddressEmployer_Address"
        ];
      }

      if (!selectedEmployment["LobbyQueue_NHHS::HistoryAddressEmployer_City"]) {
        tempEmploymentFormError[
          "LobbyQueue_NHHS::HistoryAddressEmployer_City"
        ] = "City is required";
      } else {
        delete tempEmploymentFormError[
          "LobbyQueue_NHHS::HistoryAddressEmployer_City"
        ];
      }

      if (
        !selectedEmployment["LobbyQueue_NHHS::HistoryAddressEmployer_State"] ||
        selectedEmployment["LobbyQueue_NHHS::HistoryAddressEmployer_State"] ==
          -1
      ) {
        tempEmploymentFormError[
          "LobbyQueue_NHHS::HistoryAddressEmployer_State"
        ] = "State is required";
      } else {
        delete tempEmploymentFormError[
          "LobbyQueue_NHHS::HistoryAddressEmployer_State"
        ];
      }

      if (
        !selectedEmployment[
          "LobbyQueue_NHHS::HistoryAddressEmployer_PostalCode"
        ]
      ) {
        tempEmploymentFormError[
          "LobbyQueue_NHHS::HistoryAddressEmployer_PostalCode"
        ] = "Postal code is required";
      } else {
        delete tempEmploymentFormError[
          "LobbyQueue_NHHS::HistoryAddressEmployer_PostalCode"
        ];
      }

      if (!selectedEmployment["LobbyQueue_NHHS::HistoryJobTitle"]) {
        tempEmploymentFormError["LobbyQueue_NHHS::HistoryJobTitle"] =
          "Job title is required";
      } else {
        delete tempEmploymentFormError["LobbyQueue_NHHS::HistoryJobTitle"];
      }

      if (!selectedEmployment["LobbyQueue_NHHS::HistoryJobDescription"]) {
        tempEmploymentFormError["LobbyQueue_NHHS::HistoryJobDescription"] =
          "Job description is required";
      } else {
        delete tempEmploymentFormError[
          "LobbyQueue_NHHS::HistoryJobDescription"
        ];
      }

      if (
        !selectedEmployment["LobbyQueue_NHHS::HistoryJobLevel"] ||
        selectedEmployment["LobbyQueue_NHHS::HistoryJobLevel"] == -1
      ) {
        tempEmploymentFormError["LobbyQueue_NHHS::HistoryJobLevel"] =
          "State is required";
      } else {
        delete tempEmploymentFormError["LobbyQueue_NHHS::HistoryJobLevel"];
      }

      if (
        !selectedEmployment["LobbyQueue_NHHS::Industry"] ||
        selectedEmployment["LobbyQueue_NHHS::Industry"] == -1
      ) {
        tempEmploymentFormError["LobbyQueue_NHHS::Industry"] =
          "State is required";
      } else {
        delete tempEmploymentFormError["LobbyQueue_NHHS::Industry"];
      }

      if (!selectedEmployment["LobbyQueue_NHHS::HistoryGrossWeeklyIncome"]) {
        tempEmploymentFormError["LobbyQueue_NHHS::HistoryGrossWeeklyIncome"] =
          "Job title is required";
      } else {
        delete tempEmploymentFormError[
          "LobbyQueue_NHHS::HistoryGrossWeeklyIncome"
        ];
      }

      if (!selectedEmployment["LobbyQueue_NHHS::HistoryDateStart"]) {
        tempEmploymentFormError["LobbyQueue_NHHS::HistoryDateStart"] =
          "Start date is required";
      } else {
        delete tempEmploymentFormError["LobbyQueue_NHHS::HistoryDateStart"];
      }

      if (
        !selectedEmployment["LobbyQueue_NHHS::HistoryisCurrent"] &&
        !selectedEmployment["LobbyQueue_NHHS::HistoryDateEnd"]
      ) {
        tempEmploymentFormError["LobbyQueue_NHHS::HistoryDateEnd"] =
          "End date is required";
      } else {
        delete tempEmploymentFormError["LobbyQueue_NHHS::HistoryDateEnd"];
      }

      if (
        !selectedEmployment["LobbyQueue_NHHS::HistoryisCurrent"] &&
        !selectedEmployment["LobbyQueue_NHHS::HistoryReasonLeftJob"]
      ) {
        tempEmploymentFormError["LobbyQueue_NHHS::HistoryReasonLeftJob"] =
          "End date is required";
      } else {
        delete tempEmploymentFormError["LobbyQueue_NHHS::HistoryReasonLeftJob"];
      }

      setEmploymentFormErrors(tempEmploymentFormError);
      selectedEmployment["LobbyQueue_NHHS::HistoryisCurrent"] = selectedEmployment["LobbyQueue_NHHS::HistoryisCurrent"]?'1':'0'
      if (Object.entries(tempEmploymentFormError).length) {
        return;
      } else {
        setForm({
          ...form,
          employments: [...employments, { ...selectedEmployment }],
        });
        setEmployments([...employments, { ...selectedEmployment }]);
        setSelectedEmployment(undefined);
        setShowEmploymentFormModal(false);
      }
    }
  };

  const validateGOPFormAndSubmit = () => {
    if (
      (selectedEmployment &&
        selectedEmployment["LobbyQueue_NHHS::HistoryEmploymentType"] ==
          "Government Assistance") ||
      selectedEmployment["LobbyQueue_NHHS::HistoryEmploymentType"] == "Gig" ||
      selectedEmployment["LobbyQueue_NHHS::HistoryEmploymentType"] == "Self"
    ) {
      const tempGOPFormErrors = { ...GOPFormErrors };
      if (!selectedEmployment["LobbyQueue_NHHS::HistoryEmployer"]) {
        tempGOPFormErrors["LobbyQueue_NHHS::HistoryEmployer"] =
          selectedEmployment["LobbyQueue_NHHS::HistoryEmploymentType"] ==
            "Gig" ||
          selectedEmployment["LobbyQueue_NHHS::HistoryEmploymentType"] == "Self"
            ? "Company name is required."
            : "Program name is required";
      } else {
        delete tempGOPFormErrors["LobbyQueue_NHHS::HistoryEmployer"];
      }
      if (!selectedEmployment["LobbyQueue_NHHS::HistoryGrossWeeklyIncome"]) {
        tempGOPFormErrors["LobbyQueue_NHHS::HistoryGrossWeeklyIncome"] =
          "Weekly income is required";
      } else {
        delete tempGOPFormErrors["LobbyQueue_NHHS::HistoryGrossWeeklyIncome"];
      }

      setGOPFormErrors(tempGOPFormErrors);
      if (Object.entries(tempGOPFormErrors).length) {
        return;
      } else {
        setForm({
          ...form,
          employments: [...employments, { ...selectedEmployment }],
        });
        setEmployments([...employments, { ...selectedEmployment }]);
        setSelectedEmployment(undefined);
        setShowGOPFormModal(false);
      }
    }
  };

  const validateAndNavigate = (navigate) => {
    const temp = validations(true);
    if (temp) {
      props.handleNavigation("next", navigate);
    }
  };

  const validations = (notify = false) => {
    if (!employments.length) {
      if (notify) {
        Notify("danger", "Please add employment");
      }
      return false;
    }

    const isGOP = employments.findIndex(
      (x) =>
        x["LobbyQueue_NHHS::HistoryEmploymentType"] == "Gig" ||
        x["LobbyQueue_NHHS::HistoryEmploymentType"] == "Self" ||
        x["LobbyQueue_NHHS::HistoryEmploymentType"] == "Government Assistance"
    );
    if (isGOP > -1) {
      return true;
    }

    let yearsHistory = 0;
    for (let index = 0; index < employments.length; index++) {
      const element = employments[index];
      if (element["LobbyQueue_NHHS::isGovernmentAssistance"] == "1") {
        continue;
      }
      const startDate = moment(element["LobbyQueue_NHHS::HistoryDateStart"]);
      const endDate =
        element["LobbyQueue_NHHS::HistoryisCurrent"] == "1"
          ? moment()
          : moment(element["LobbyQueue_NHHS::HistoryDateEnd"]);
      yearsHistory += endDate.diff(startDate, "years", true);
    }
    if (yearsHistory < 5) {
      if (notify) {
        Notify(
          "danger",
          "Please add at least 5 years of employment history or GOP."
        );
      }
      return false;
    } else {
      return true;
    }
  };

  const renderEmploymentHistory = () => {
    return (
      <>
        <div className="row mt-5">
          <div className="col-12">
            <h3 className="d-flex flex-row justify-content-between">
              <span>{t('tenantApp.employment_details.employment_history')}</span>
              <button
                className="btn btn-primary ml-5"
                onClick={() => {
                  setSelectedEmployment(undefined);
                  setShowEmploymentOptionModal(true);
                }}
              >
                {t('tenantApp.employment_details.add_employment')}
              </button>
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p>
            {t('tenantApp.employment_details.description')}.
            </p>
          </div>
        </div>

        <div className="row mt-3" style={{ minHeight: 200 }}>
          {employments?.length ? (
            <Table striped responsive>
              <thead>
                <tr>
                  <th>{t('tenantApp.employment_details.company_name')}</th>
                  <th>{t('tenantApp.employment_details.manager')}</th>
                  <th>{t('tenantApp.employment_details.phone')}</th>
                  <th>{t('tenantApp.employment_details.job_title')}</th>
                  <th>{t('tenantApp.employment_details.start_date')}</th>
                  <th>{t('tenantApp.employment_details.end_date')}</th>
                  <th>{t('tenantApp.employment_details.weekly_income')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {employments?.map((employment, index) => {
                  return (
                    <tr key={index + Math.random() + ""}>
                      <td>{employment["LobbyQueue_NHHS::HistoryEmployer"]}</td>
                      <td>
                        {employment["LobbyQueue_NHHS::HistorySupervisor"] ||
                          "N/A"}
                      </td>
                      <td>
                        {employment["LobbyQueue_NHHS::HistoryEmployerPhone"] ||
                          "N/A"}
                      </td>
                      <td>
                        {employment["LobbyQueue_NHHS::HistoryJobTitle"] ||
                          "N/A"}
                      </td>
                      <td>
                        {employment["LobbyQueue_NHHS::HistoryDateStart"]?moment(employment["LobbyQueue_NHHS::HistoryDateStart"]).format('MM/DD/YYYY'):
                          "N/A"}
                      </td>
                      <td>
                        {employment["LobbyQueue_NHHS::HistoryisCurrent"]
                          ? "till present"
                          : employment["LobbyQueue_NHHS::HistoryDateEnd"] ||
                            "N/A"}
                      </td>
                      <td className="text-primary" style={{ fontWeight: 900 }}>
                        $
                        {
                          employment[
                            "LobbyQueue_NHHS::HistoryGrossWeeklyIncome"
                          ]
                        }
                      </td>
                      <td>
                        {/* <i
                          role="button"
                          className="fa fa-edit text-primary"
                        ></i> */}
                        <i
                          role="button"
                          className="fa fa-trash text-danger ml-3"
                          onClick={() => {
                            const temp = [...employments];
                            temp.splice(index, 1);
                            setEmployments(temp);
                          }}
                        ></i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : null}
        </div>
      </>
    );
  };

  const renderEmploymentOptionModal = () => {
    return (
      <Modal
        isOpen={showEmploymentOptionModal}
        toggle={() => setShowEmploymentOptionModal(!showEmploymentOptionModal)}
        size="sm"
        centered
      >
        <ModalHeader className="w-100 position-relative">
          <span>{t('tenantApp.employment_details.employment_type')}</span>
          <span
            className="fa fa-times"
            style={{ position: "absolute", top: "35%", right: 20 }}
            role="button"
            onClick={() =>
              setShowEmploymentOptionModal(!showEmploymentOptionModal)
            }
          ></span>
        </ModalHeader>
        <ModalBody style={{ background: "white" }}>
          <Row>
            <Col xs="12">
              <button
                className="btn btn-primary w-100"
                onClick={() => {
                  setShowEmploymentOptionModal(false);
                  setSelectedEmployment({
                    "LobbyQueue_NHHS::isGovernmentAssistance": "0",
                    "LobbyQueue_NHHS::isSelfEmployed": "0",
                    "LobbyQueue_NHHS::HistoryEmploymentType": "W2",
                  });
                  setEmploymentFormErrors({});
                  setShowEmploymentFormModal(true);
                }}
              >
                {t('tenantApp.employment_details.regular_employment')}
              </button>
            </Col>
            <Col xs="12">
              <button
                className="btn btn-primary w-100 mt-1"
                onClick={() => {
                  setShowEmploymentOptionModal(false);
                  setSelectedEmployment({
                    "LobbyQueue_NHHS::isGovernmentAssistance": "1",
                    "LobbyQueue_NHHS::isSelfEmployed": "0",
                    "LobbyQueue_NHHS::HistoryEmploymentType":
                      "Government Assistance",
                  });
                  setGOPFormErrors({});
                  setShowGOPFormModal(true);
                }}
              >
                {t('tenantApp.employment_details.government_assistance')}
              </button>
            </Col>
            <Col xs="12">
              <button
                className="btn btn-primary w-100 mt-1"
                onClick={() => {
                  setShowEmploymentOptionModal(false);
                  setSelectedEmployment({
                    "LobbyQueue_NHHS::isGovernmentAssistance": "0",
                    "LobbyQueue_NHHS::isSelfEmployed": "1",
                    "LobbyQueue_NHHS::HistoryEmploymentType": "Gig",
                  });
                  setGOPFormErrors({});
                  setShowGOPFormModal(true);
                }}
              >
                {t('tenantApp.employment_details.gig_work')}
              </button>
            </Col>
            <Col xs="12">
              <button
                className="btn btn-primary w-100 mt-1"
                onClick={() => {
                  setShowEmploymentOptionModal(false);
                  setSelectedEmployment({
                    "LobbyQueue_NHHS::isGovernmentAssistance": "0",
                    "LobbyQueue_NHHS::isSelfEmployed": "1",
                    "LobbyQueue_NHHS::HistoryEmploymentType": "Self",
                  });
                  setGOPFormErrors({});
                  setShowGOPFormModal(true);
                }}
              >
                {t('tenantApp.employment_details.self_employed')}
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const renderEmploymentFormModal = () => {
    return (
      <Modal
        isOpen={showEmploymentFormModal}
        toggle={() => {
          if (showEmploymentFormModal) {
            setEmploymentFormErrors({});
            setSelectedEmployment(undefined);
          }
          setShowEmploymentFormModal(!showEmploymentFormModal);
        }}
        size="lg"
        centered
      >
        <ModalHeader className="w-100 position-relative">
          <span>{t('tenantApp.employment_details.add_employment_history')}</span>
          <span
            className="fa fa-times"
            style={{ position: "absolute", top: "35%", right: 20 }}
            role="button"
            onClick={() => {
              setEmploymentFormErrors({});
              setSelectedEmployment(undefined);
              setShowEmploymentFormModal(false);
            }}
          ></span>
        </ModalHeader>
        <ModalBody style={{ background: "white" }}>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group">
                <label className="form-label">{t('tenantApp.employment_details.company_name')}*</label>
                <input
                  type="text"
                  className={`form-control ${
                    employmentFormErrors["LobbyQueue_NHHS::HistoryEmployer"]
                      ? "border-danger"
                      : ""
                  }`}
                  placeholder={"Enter company name"}
                  value={
                    selectedEmployment &&
                    selectedEmployment["LobbyQueue_NHHS::HistoryEmployer"]
                      ? selectedEmployment["LobbyQueue_NHHS::HistoryEmployer"]
                      : ""
                  }
                  onChange={(e) => {
                    setEmploymentFormErrors({
                      ...employmentFormErrors,
                      "LobbyQueue_NHHS::HistoryEmployer": "",
                    });
                    setSelectedEmployment({
                      ...selectedEmployment,
                      "LobbyQueue_NHHS::HistoryEmployer": e.target.value,
                    });
                  }}
                />
                <span className="text-danger">
                  {employmentFormErrors["LobbyQueue_NHHS::HistoryEmployer"]}
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group">
                <label className="form-label">{t('tenantApp.employment_details.manager')}*</label>
                <input
                  type="text"
                  className={`form-control ${
                    employmentFormErrors["LobbyQueue_NHHS::HistorySupervisor"]
                      ? "border-danger"
                      : ""
                  }`}
                  placeholder={"Enter manager/supervisor name"}
                  value={
                    selectedEmployment &&
                    selectedEmployment["LobbyQueue_NHHS::HistorySupervisor"]
                      ? selectedEmployment["LobbyQueue_NHHS::HistorySupervisor"]
                      : ""
                  }
                  onChange={(e) => {
                    setEmploymentFormErrors({
                      ...employmentFormErrors,
                      "LobbyQueue_NHHS::HistorySupervisor": "",
                    });
                    setSelectedEmployment({
                      ...selectedEmployment,
                      "LobbyQueue_NHHS::HistorySupervisor": e.target.value,
                    });
                  }}
                />
                <span className="text-danger">
                  {employmentFormErrors["LobbyQueue_NHHS::HistorySupervisor"]}
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group">
                <label className="form-label">{t('tenantApp.employment_details.company_phone')}*</label>
                <input
                  type="text"
                  className={`form-control ${
                    employmentFormErrors[
                      "LobbyQueue_NHHS::HistoryEmployerPhone"
                    ]
                      ? "border-danger"
                      : ""
                  }`}
                  placeholder={"Enter company phone"}
                  value={
                    selectedEmployment &&
                    selectedEmployment["LobbyQueue_NHHS::HistoryEmployerPhone"]
                      ? selectedEmployment[
                          "LobbyQueue_NHHS::HistoryEmployerPhone"
                        ]
                      : ""
                  }
                  onChange={(e) => {
                    let x = e.target.value
                      .replace(/\D/g, "")
                      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                    e.target.value = !x[2]
                      ? x[1]
                      : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
                    setEmploymentFormErrors({
                      ...employmentFormErrors,
                      "LobbyQueue_NHHS::HistoryEmployerPhone": "",
                    });
                    setSelectedEmployment({
                      ...selectedEmployment,
                      "LobbyQueue_NHHS::HistoryEmployerPhone": e.target.value,
                    });
                  }}
                />
                <span className="text-danger">
                  {
                    employmentFormErrors[
                      "LobbyQueue_NHHS::HistoryEmployerPhone"
                    ]
                  }
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group">
                <label className="form-label">{t('tenantApp.employment_details.address')}*</label>
                <input
                  type="text"
                  className={`form-control ${
                    employmentFormErrors[
                      "LobbyQueue_NHHS::HistoryAddressEmployer_Address"
                    ]
                      ? "border-danger"
                      : ""
                  }`}
                  placeholder={"Enter company address"}
                  value={
                    selectedEmployment &&
                    selectedEmployment[
                      "LobbyQueue_NHHS::HistoryAddressEmployer_Address"
                    ]
                      ? selectedEmployment[
                          "LobbyQueue_NHHS::HistoryAddressEmployer_Address"
                        ]
                      : ""
                  }
                  onChange={(e) => {
                    setEmploymentFormErrors({
                      ...employmentFormErrors,
                      "LobbyQueue_NHHS::HistoryAddressEmployer_Address": "",
                    });
                    setSelectedEmployment({
                      ...selectedEmployment,
                      "LobbyQueue_NHHS::HistoryAddressEmployer_Address":
                        e.target.value,
                    });
                  }}
                />
                <span className="text-danger">
                  {
                    employmentFormErrors[
                      "LobbyQueue_NHHS::HistoryAddressEmployer_Address"
                    ]
                  }
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group">
                <label className="form-label">{t('tenantApp.employment_details.city')}*</label>
                <input
                  type="text"
                  className={`form-control ${
                    employmentFormErrors[
                      "LobbyQueue_NHHS::HistoryAddressEmployer_City"
                    ]
                      ? "border-danger"
                      : ""
                  }`}
                  placeholder={"Enter city"}
                  value={
                    selectedEmployment &&
                    selectedEmployment[
                      "LobbyQueue_NHHS::HistoryAddressEmployer_City"
                    ]
                      ? selectedEmployment[
                          "LobbyQueue_NHHS::HistoryAddressEmployer_City"
                        ]
                      : ""
                  }
                  onChange={(e) => {
                    setEmploymentFormErrors({
                      ...employmentFormErrors,
                      "LobbyQueue_NHHS::HistoryAddressEmployer_City": "",
                    });
                    setSelectedEmployment({
                      ...selectedEmployment,
                      "LobbyQueue_NHHS::HistoryAddressEmployer_City":
                        e.target.value,
                    });
                  }}
                />
                <span className="text-danger">
                  {
                    employmentFormErrors[
                      "LobbyQueue_NHHS::HistoryAddressEmployer_City"
                    ]
                  }
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group">
                <label className="form-label">{t('tenantApp.employment_details.state')}*</label>
                <Select
                  defaultValue={
                    selectedEmployment &&
                    selectedEmployment[
                      "LobbyQueue_NHHS::HistoryAddressEmployer_State"
                    ]
                      ? {
                          label:
                            selectedEmployment[
                              "LobbyQueue_NHHS::HistoryAddressEmployer_State"
                            ],
                          value:
                            selectedEmployment[
                              "LobbyQueue_NHHS::HistoryAddressEmployer_State"
                            ],
                        }
                      : ""
                  }
                  onChange={(e) => {
                    setEmploymentFormErrors({
                      ...employmentFormErrors,
                      "LobbyQueue_NHHS::HistoryAddressEmployer_State": "",
                    });
                    setSelectedEmployment({
                      ...selectedEmployment,
                      "LobbyQueue_NHHS::HistoryAddressEmployer_State": e.value,
                    });
                  }}
                  options={states.map((x) => {
                    return {
                      value: x,
                      label: x,
                    };
                  })}
                  isSearchable
                  placeholder="Select one"
                  className={`${
                    employmentFormErrors[
                      "LobbyQueue_NHHS::HistoryAddressEmployer_State"
                    ]
                      ? "border-danger"
                      : ""
                  }`}
                />
                <span className="text-danger">
                  {
                    employmentFormErrors[
                      "LobbyQueue_NHHS::HistoryAddressEmployer_State"
                    ]
                  }
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group">
                <label className="form-label">{t('tenantApp.employment_details.postal_code')}*</label>
                <input
                  type="text"
                  className={`form-control ${
                    employmentFormErrors[
                      "LobbyQueue_NHHS::HistoryAddressEmployer_PostalCode"
                    ]
                      ? "border-danger"
                      : ""
                  }`}
                  placeholder={"Enter postcode"}
                  value={
                    selectedEmployment &&
                    selectedEmployment[
                      "LobbyQueue_NHHS::HistoryAddressEmployer_PostalCode"
                    ]
                      ? selectedEmployment[
                          "LobbyQueue_NHHS::HistoryAddressEmployer_PostalCode"
                        ]
                      : ""
                  }
                  onChange={(e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9.]/g, "")
                      .replace(/(\..*)\./g);
                    setEmploymentFormErrors({
                      ...employmentFormErrors,
                      "LobbyQueue_NHHS::HistoryAddressEmployer_PostalCode": "",
                    });
                    setSelectedEmployment({
                      ...selectedEmployment,
                      "LobbyQueue_NHHS::HistoryAddressEmployer_PostalCode":
                        e.target.value,
                    });
                  }}
                />
                <span className="text-danger">
                  {
                    employmentFormErrors[
                      "LobbyQueue_NHHS::HistoryAddressEmployer_PostalCode"
                    ]
                  }
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group">
                <label className="form-label">{t('tenantApp.employment_details.job_title')}*</label>
                <input
                  type="text"
                  className={`form-control ${
                    employmentFormErrors["LobbyQueue_NHHS::HistoryJobTitle"]
                      ? "border-danger"
                      : ""
                  }`}
                  placeholder={"Enter job title"}
                  value={
                    selectedEmployment &&
                    selectedEmployment["LobbyQueue_NHHS::HistoryJobTitle"]
                      ? selectedEmployment["LobbyQueue_NHHS::HistoryJobTitle"]
                      : ""
                  }
                  onChange={(e) => {
                    setEmploymentFormErrors({
                      ...employmentFormErrors,
                      "LobbyQueue_NHHS::HistoryJobTitle": "",
                    });
                    setSelectedEmployment({
                      ...selectedEmployment,
                      "LobbyQueue_NHHS::HistoryJobTitle": e.target.value,
                    });
                  }}
                />
                <span className="text-danger">
                  {employmentFormErrors["LobbyQueue_NHHS::HistoryJobTitle"]}
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group">
                <label className="form-label">{t('tenantApp.employment_details.job_description')}*</label>
                <input
                  type="text"
                  className={`form-control ${
                    employmentFormErrors[
                      "LobbyQueue_NHHS::HistoryJobDescription"
                    ]
                      ? "border-danger"
                      : ""
                  }`}
                  placeholder={"Enter job description"}
                  value={
                    selectedEmployment &&
                    selectedEmployment["LobbyQueue_NHHS::HistoryJobDescription"]
                      ? selectedEmployment[
                          "LobbyQueue_NHHS::HistoryJobDescription"
                        ]
                      : ""
                  }
                  onChange={(e) => {
                    setEmploymentFormErrors({
                      ...employmentFormErrors,
                      "LobbyQueue_NHHS::HistoryJobDescription": "",
                    });
                    setSelectedEmployment({
                      ...selectedEmployment,
                      "LobbyQueue_NHHS::HistoryJobDescription": e.target.value,
                    });
                  }}
                />
                <span className="text-danger">
                  {
                    employmentFormErrors[
                      "LobbyQueue_NHHS::HistoryJobDescription"
                    ]
                  }
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group">
                <label className="form-label">{t('tenantApp.employment_details.job_level')}*</label>
                <Select
                  defaultValue={
                    selectedEmployment &&
                    selectedEmployment["LobbyQueue_NHHS::HistoryJobLevel"]
                      ? {
                          label:
                            selectedEmployment[
                              "LobbyQueue_NHHS::HistoryJobLevel"
                            ],
                          value:
                            selectedEmployment[
                              "LobbyQueue_NHHS::HistoryJobLevel"
                            ],
                        }
                      : ""
                  }
                  onChange={(e) => {
                    setEmploymentFormErrors({
                      ...employmentFormErrors,
                      "LobbyQueue_NHHS::HistoryJobLevel": "",
                    });
                    setSelectedEmployment({
                      ...selectedEmployment,
                      "LobbyQueue_NHHS::HistoryJobLevel": e.value,
                    });
                  }}
                  options={["Full Time", "Part Time", "Contract"].map((x) => {
                    return {
                      value: x,
                      label: x,
                    };
                  })}
                  isSearchable
                  placeholder="Select one"
                  className={`${
                    employmentFormErrors["LobbyQueue_NHHS::HistoryJobLevel"]
                      ? "border-danger"
                      : ""
                  }`}
                />
                <span className="text-danger">
                  {employmentFormErrors["LobbyQueue_NHHS::HistoryJobLevel"]}
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group">
                <label className="form-label">{t('tenantApp.employment_details.industry')}*</label>
                <Select
                  defaultValue={
                    selectedEmployment &&
                    selectedEmployment["LobbyQueue_NHHS::Industry"]
                      ? {
                          label:
                            selectedEmployment["LobbyQueue_NHHS::Industry"],
                          value:
                            selectedEmployment["LobbyQueue_NHHS::Industry"],
                        }
                      : ""
                  }
                  onChange={(e) => {
                    setEmploymentFormErrors({
                      ...employmentFormErrors,
                      "LobbyQueue_NHHS::Industry": "",
                    });
                    setSelectedEmployment({
                      ...selectedEmployment,
                      "LobbyQueue_NHHS::Industry": e.value,
                    });
                  }}
                  options={industries.map((x) => {
                    return {
                      value: x,
                      label: x,
                    };
                  })}
                  isSearchable
                  placeholder="Select one"
                  className={`${
                    employmentFormErrors["LobbyQueue_NHHS::Industry"]
                      ? "border-danger"
                      : ""
                  }`}
                />
                <span className="text-danger">
                  {employmentFormErrors["LobbyQueue_NHHS::Industry"]}
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group">
                <label className="form-label">{t('tenantApp.employment_details.weekly_income')}*</label>
                <input
                  type="text"
                  className={`form-control ${
                    employmentFormErrors[
                      "LobbyQueue_NHHS::HistoryGrossWeeklyIncome"
                    ]
                      ? "border-danger"
                      : ""
                  }`}
                  placeholder={"Enter job description"}
                  value={
                    selectedEmployment &&
                    selectedEmployment[
                      "LobbyQueue_NHHS::HistoryGrossWeeklyIncome"
                    ]
                      ? selectedEmployment[
                          "LobbyQueue_NHHS::HistoryGrossWeeklyIncome"
                        ]
                      : ""
                  }
                  onChange={(e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9.]/g, "")
                      .replace(/(\..*)\./g);
                    setEmploymentFormErrors({
                      ...employmentFormErrors,
                      "LobbyQueue_NHHS::HistoryGrossWeeklyIncome": "",
                    });
                    setSelectedEmployment({
                      ...selectedEmployment,
                      "LobbyQueue_NHHS::HistoryGrossWeeklyIncome":
                        e.target.value,
                    });
                  }}
                />
                <span className="text-danger">
                  {
                    employmentFormErrors[
                      "LobbyQueue_NHHS::HistoryGrossWeeklyIncome"
                    ]
                  }
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group">
                <label className="form-label">{t('tenantApp.employment_details.start_date')}*</label>
                <input
                  type="date"
                  className={`form-control ${
                    employmentFormErrors["LobbyQueue_NHHS::HistoryDateStart"]
                      ? "border-danger"
                      : ""
                  }`}
                  value={
                    selectedEmployment &&
                    selectedEmployment["LobbyQueue_NHHS::HistoryDateStart"]
                      ? selectedEmployment["LobbyQueue_NHHS::HistoryDateStart"]
                      : ""
                  }
                  onChange={(e) => {
                    setEmploymentFormErrors({
                      ...employmentFormErrors,
                      "LobbyQueue_NHHS::HistoryDateStart": "",
                    });
                    setSelectedEmployment({
                      ...selectedEmployment,
                      "LobbyQueue_NHHS::HistoryDateStart": e.target.value,
                    });
                  }}
                />
                <span className="text-danger">
                  {employmentFormErrors["LobbyQueue_NHHS::HistoryDateStart"]}
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group d-flex justify-content-between align-items-center h-100">
                <span>Still with the employer*</span>
                <input
                  type="checkbox"
                  style={{ width: 15, height: 15 }}
                  checked={
                    selectedEmployment &&
                    selectedEmployment["LobbyQueue_NHHS::HistoryisCurrent"]
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    setSelectedEmployment({
                      ...selectedEmployment,
                      "LobbyQueue_NHHS::HistoryisCurrent":
                        !selectedEmployment[
                          "LobbyQueue_NHHS::HistoryisCurrent"
                        ],
                    });
                  }}
                />
              </div>
            </div>
            {selectedEmployment &&
            !selectedEmployment["LobbyQueue_NHHS::HistoryisCurrent"] ? (
              <>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label className="form-label">{t('tenantApp.employment_details.end_date')}*</label>
                    <input
                      type="date"
                      className={`form-control ${
                        employmentFormErrors["LobbyQueue_NHHS::HistoryDateEnd"]
                          ? "border-danger"
                          : ""
                      }`}
                      value={
                        selectedEmployment &&
                        selectedEmployment["LobbyQueue_NHHS::HistoryDateEnd"]
                          ? selectedEmployment[
                              "LobbyQueue_NHHS::HistoryDateEnd"
                            ]
                          : ""
                      }
                      onChange={(e) => {
                        setEmploymentFormErrors({
                          ...employmentFormErrors,
                          "LobbyQueue_NHHS::HistoryDateEnd": "",
                        });
                        setSelectedEmployment({
                          ...selectedEmployment,
                          "LobbyQueue_NHHS::HistoryDateEnd": e.target.value,
                        });
                      }}
                    />
                    <span className="text-danger">
                      {employmentFormErrors["LobbyQueue_NHHS::HistoryDateEnd"]}
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label className="form-label">{t('tenantApp.employment_details.reason_leaving')}*</label>
                    <textarea
                      className={`form-control ${
                        employmentFormErrors[
                          "LobbyQueue_NHHS::HistoryReasonLeftJob"
                        ]
                          ? "border-danger"
                          : ""
                      }`}
                      value={
                        selectedEmployment &&
                        selectedEmployment[
                          "LobbyQueue_NHHS::HistoryReasonLeftJob"
                        ]
                          ? selectedEmployment[
                              "LobbyQueue_NHHS::HistoryReasonLeftJob"
                            ]
                          : ""
                      }
                      onChange={(e) => {
                        setEmploymentFormErrors({
                          ...employmentFormErrors,
                          "LobbyQueue_NHHS::HistoryReasonLeftJob": "",
                        });
                        setSelectedEmployment({
                          ...selectedEmployment,
                          "LobbyQueue_NHHS::HistoryReasonLeftJob":
                            e.target.value,
                        });
                      }}
                    ></textarea>
                    <span className="text-danger">
                      {
                        employmentFormErrors[
                          "LobbyQueue_NHHS::HistoryReasonLeftJob"
                        ]
                      }
                    </span>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-secondary"
            onClick={() => {
              setSelectedEmployment(undefined);
              setShowEmploymentFormModal(false);
              setEmploymentFormErrors({});
            }}
          >
            {t('tenantApp.employment_details.cancel')}
          </button>
          <button
            className="btn btn-primary"
            onClick={() => validateEmploymentFormAndSubmit()}
          >
            {t('tenantApp.employment_details.add_employment')}
          </button>
        </ModalFooter>
      </Modal>
    );
  };

  const renderGOPFormModal = () => {
    return (
      <Modal
        isOpen={showGOPFormModal}
        toggle={() => {
          if (showEmploymentFormModal) {
            setGOPFormErrors({});
            setSelectedEmployment(undefined);
          }
          setShowGOPFormModal(!showGOPFormModal);
        }}
        size="lg"
        centered
      >
        <ModalHeader className="w-100 position-relative">
          <span>{t('tenantApp.employment_details.government_assistance_details')}</span>
          <span
            className="fa fa-times"
            style={{ position: "absolute", top: "35%", right: 20 }}
            role="button"
            onClick={() => {
              setGOPFormErrors({});
              setSelectedEmployment(undefined);
              setShowGOPFormModal(false);
            }}
          ></span>
        </ModalHeader>
        <ModalBody style={{ background: "white" }}>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="form-group">
                <label className="form-label">
                  {selectedEmployment &&
                  (selectedEmployment[
                    "LobbyQueue_NHHS::HistoryEmploymentType"
                  ] == "Gig" ||
                    selectedEmployment[
                      "LobbyQueue_NHHS::HistoryEmploymentType"
                    ] == "Self")
                    ? t('tenantApp.employment_details.company_name') + "*"
                    : t('tenantApp.employment_details.program_name')+"*"}
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    GOPFormErrors["LobbyQueue_NHHS::HistoryEmployer"]
                      ? "border-danger"
                      : ""
                  }`}
                  placeholder={"Enter program name"}
                  value={
                    selectedEmployment &&
                    selectedEmployment["LobbyQueue_NHHS::HistoryEmployer"]
                      ? selectedEmployment["LobbyQueue_NHHS::HistoryEmployer"]
                      : ""
                  }
                  onChange={(e) => {
                    setGOPFormErrors({
                      ...GOPFormErrors,
                      "LobbyQueue_NHHS::HistoryEmployer": "",
                    });
                    setSelectedEmployment({
                      ...selectedEmployment,
                      "LobbyQueue_NHHS::HistoryEmployer": e.target.value,
                    });
                  }}
                />
                <span className="text-danger">
                  {GOPFormErrors["LobbyQueue_NHHS::HistoryEmployer"]}
                </span>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-4">
              <div className="form-group">
                <label className="form-label">{t('tenantApp.employment_details.weekly_income')}*</label>
                <input
                  type="text"
                  className={`form-control ${
                    GOPFormErrors["LobbyQueue_NHHS::HistoryGrossWeeklyIncome"]
                      ? "border-danger"
                      : ""
                  }`}
                  placeholder={"Enter weekly income"}
                  value={
                    selectedEmployment &&
                    selectedEmployment[
                      "LobbyQueue_NHHS::HistoryGrossWeeklyIncome"
                    ]
                      ? selectedEmployment[
                          "LobbyQueue_NHHS::HistoryGrossWeeklyIncome"
                        ]
                      : ""
                  }
                  onChange={(e) => {
                    setGOPFormErrors({
                      ...GOPFormErrors,
                      "LobbyQueue_NHHS::HistoryGrossWeeklyIncome": "",
                    });
                    setSelectedEmployment({
                      ...selectedEmployment,
                      "LobbyQueue_NHHS::HistoryGrossWeeklyIncome":
                        e.target.value,
                    });
                  }}
                />
                <span className="text-danger">
                  {GOPFormErrors["LobbyQueue_NHHS::HistoryGrossWeeklyIncome"]}
                </span>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-secondary"
            onClick={() => {
              setGOPFormErrors({});
              setSelectedEmployment(undefined);
              setShowGOPFormModal(false);
            }}
          >
            {t('tenantApp.employment_details.cancel')}
          </button>
          <button
            className="btn btn-primary"
            onClick={() => validateGOPFormAndSubmit()}
          >
            {t('tenantApp.employment_details.add_employment')}
          </button>
        </ModalFooter>
      </Modal>
    );
  };

  const renderSpouseEmployment = () => {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <h3>{t('tenantApp.employment_details.spouse_coresident')}</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.employment_details.current_employer')}</label>
              <input
                type="text"
                className={`form-control`}
                placeholder={"Enter name"}
                value={form?.Employer_Spouse || ""}
                onChange={(e) => {
                  setForm({ ...form, Employer_Spouse: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.employment_details.employer_phone')}</label>
              <input
                type="text"
                className={`form-control`}
                placeholder={"Enter phone"}
                value={form?.SpouseEmployerPhone || ""}
                onChange={(e) => {
                  let x = e.target.value
                    .replace(/\D/g, "")
                    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                  e.target.value = !x[2]
                    ? x[1]
                    : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
                  setForm({ ...form, SpouseEmployerPhone: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.employment_details.years')}</label>
              <input
                type="text"
                className={`form-control`}
                placeholder={"Enter years"}
                value={form?.Years_JobSpouse || ""}
                onChange={(e) => {
                  setForm({ ...form, Years_JobSpouse: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.employment_details.weekly_income')}</label>
              <input
                type="text"
                className={`form-control`}
                placeholder={"Enter weekly income"}
                value={form?.SpouseGrossWeeklyIncome || ""}
                onChange={(e) => {
                  setForm({ ...form, SpouseGrossWeeklyIncome: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.employment_details.address')}</label>
              <input
                type="text"
                className={`form-control`}
                placeholder={"Enter address"}
                value={form?.AddressSpouseEmployer_Address || ""}
                onChange={(e) => {
                  setForm({
                    ...form,
                    AddressSpouseEmployer_Address: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.employment_details.city')}</label>
              <input
                type="text"
                className={`form-control`}
                placeholder={"Enter city"}
                value={form?.AddressSpouseEmployer_City || ""}
                onChange={(e) => {
                  setForm({
                    ...form,
                    AddressSpouseEmployer_City: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.employment_details.state')}</label>
              <input
                type="text"
                className={`form-control`}
                placeholder={"Enter state"}
                value={form?.AddressSpouseEmployer_State || ""}
                onChange={(e) => {
                  setForm({
                    ...form,
                    AddressSpouseEmployer_State: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.employment_details.postal_code')}</label>
              <input
                type="text"
                className={`form-control`}
                placeholder={"Enter postal code"}
                maxLength={5}
                value={form?.AddressSpouseEmployer_PostalCode || ""}
                onChange={(e) => {
                  setForm({
                    ...form,
                    AddressSpouseEmployer_PostalCode: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.employment_details.job_title')}</label>
              <input
                type="text"
                className={`form-control`}
                placeholder={"Enter job title"}
                value={form?.SpouseJobTitle || ""}
                onChange={(e) => {
                  setForm({ ...form, SpouseJobTitle: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.employment_details.supervisor')}</label>
              <input
                type="text"
                className={`form-control`}
                placeholder={"Enter manage or supervisor name"}
                value={form?.SpouseSupervisor || ""}
                onChange={(e) => {
                  setForm({ ...form, SpouseSupervisor: e.target.value });
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderNavigationButton = () => {
    return (
      <>
        <div className="row mt-4 justify-content-end">
          <div className="col-4 col-lg-2">
            <button
              type="button"
              className="btn btn-secondary btn-block"
              onClick={() => {
                props.handleNavigation("previous");
              }}
              disabled={loading}
            >
              {t('tenantApp.navigation.previous')}
              {loading ? (
                <span className="spinner-border spinner-border-sm float-right text-black"></span>
              ) : null}
            </button>
          </div>
          <div className="col-4 col-lg-2">
            <button
              type="button"
              onClick={() => {
                validateAndNavigate(false);
              }}
              className="btn btn-success btn-block"
              disabled={loading}
            >
              {t('tenantApp.navigation.save')}
              {loading ? (
                <span className="spinner-border spinner-border-sm float-right"></span>
              ) : null}
            </button>
          </div>
          <div className="col-4 col-lg-2">
            <button
              type="submit"
              className="btn btn-warning btn-block"
              onClick={() => validateAndNavigate(true)}
              disabled={loading || !enableNext}
            >
              {t('tenantApp.navigation.next')}
              {loading ? (
                <span className="spinner-border spinner-border-sm float-right"></span>
              ) : null}
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {renderEmploymentHistory()}
      {form &&
        (form["OtherResidents01(1)"] ||
          form["OtherResidents01(2)"] ||
          form["OtherResidents01(3)"]) &&
        form["MaritalStatus"] === "Married" &&
        renderSpouseEmployment()}
      {renderEmploymentOptionModal()}
      {renderEmploymentFormModal()}
      {renderGOPFormModal()}
      {renderNavigationButton()}
    </>
  );
};

export default EmploymentHistory;

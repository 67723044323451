export const spanishMenu = [
  {
    path: "/dashboard",
    icon: "fa fa-th",
    title: "Tablero",
  },
  {
    path: "/maintenance",
    icon: "ion-md-construct",
    title: "Ticket de mantenimiento",
  },
  {
    path: "/payments",
    icon: "fa fa-dollar-sign",
    title: "Pagos",
  },
  {
    path: "/parking",
    icon: "ion-md-car",
    title: "Estacionamiento",
  },
  {
    path: "/people",
    icon: "ion-md-people",
    title: "Personas",
  },
];

export const Menu = [
  {
    path: "/dashboard",
    icon: "fa fa-th",
    title: "Dashboard",
  },
  // TODO: payment on mongo
  // {
  //   path: "/maintenance",
  //   icon: "ion-md-construct",
  //   title: "Maintenance Tickets",
  // },
  // {
  //   path: "/payments",
  //   icon: "fa fa-dollar-sign",
  //   title: "Payments",
  // },
  // {
  //   path: "/parking",
  //   icon: "ion-md-car",
  //   title: "Parking",
  // },
  // {
  //   path: "/people",
  //   icon: "ion-md-people",
  //   title: "People",
  // },
];

import React, { useState, memo } from "react";
import InputMask from "react-input-mask";
import { MakePayment } from "../../redux/actions/payments";
import { connect } from "react-redux";
import valid from "card-validator";
import { Validation } from "../../helpers/validations";
import { errors, spanishErrors, cardImages } from "./constants";
import WrapperComponent from "../wrapperComponent";
import PaymentAddress from "./paymentAddress";
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const PaymentForm = memo(({ MakePayment, loading, user = {}, setIsCompleted, setIsFailed, setOpenPaymentAlert, closeModal, setTransactionResponse, resfreshData, setRefreshData }) => {
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardCode, setCardCode] = useState("");
  const [expirationDate, setExpiration] = useState("");
  const [error, setError] = useState({});
  const [cardImage, setCardImage] = useState(cardImages["default"]);
  const [isNextStep, setIsNextStep] = useState(false);
  const [address, setAddress] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
  });

  const { t } = useTranslation();

  const onCompletion = (data) => {
    setIsFailed(data?.data.isVoided);
    setTransactionResponse(data.message)
    setRefreshData(!resfreshData);
    setIsCompleted(true);
    closeModal(true);
    setOpenPaymentAlert(true);
  };

  // callback function on successfull submission
  const handleSubmit = (e) => {
    e.preventDefault();
    let fields = ["amount", "name", "cardNumber", "expirationDate", "cardCode"];
    let isvalid = false;
    for (let i = 0; i < fields.length; i++) {
      isvalid = handleValidation(fields[i]);
      if (!isvalid) break;
    }
    if (!isvalid) return;
    let payload = {
      name,
      amount: parseFloat(amount),
      cardNumber: cardNumber.toString(),
      expirationDate,
      cardCode: cardCode.toString(),
      email: user.Email_01,
      NameFull: user.NameFull,
      addressBillTo: address,
      addressShipTo: {
        city: user.leaseDetails["Lease_PROP::Address_City"],
        state: user.leaseDetails["Lease_PROP::Address_State"],
        postalCode: user.leaseDetails["Lease_PROP::Address_PostalCode"],
        addressLine: user.leaseDetails["Lease_PROP::Address_Address01_finalDisplay"],
      },
    };
    // console.log(payload)
    MakePayment(payload, onCompletion);
  };

  // validator function
  const handleValidation = (type) => {
    // let errText = localStorage.getItem('i18nextLng') === "es" ? `spanishErrors` : `errors`
    let lang = localStorage.getItem('i18nextLng') === "es" ? true : false
    if (type == "name") {
      if (!Validation.name(name)) {
        setError({ ...error, name: lang ? spanishErrors.name : errors.name });
        return false;
      }
    } else if (type == "amount") {
      let minAmount = parseInt(user.leaseDetails.Rent_Monthly_chargeTenant) > 100 ? 100 : 10;
      if (!amount || parseInt(amount) < minAmount) {
        setError({ ...error, amount: lang ? `${spanishErrors.amount}${minAmount}` : `${errors.amount}${minAmount}` });
        return false;
      }
    } else if (type == "cardNumber") {
      let numberValidation = valid.number(cardNumber, {
        luhnValidateUnionPay: true,
      });
      let test = numberValidation.isValid;
      if (!numberValidation.isValid) {
        setError({ ...error, cardNumber: lang ? spanishErrors.cardNumber : errors.cardNumber });
        return false;
      }
    } else if (type == "expirationDate") {
      let expireValidation = valid.expirationDate(expirationDate);
      if (!expireValidation.isPotentiallyValid || !expireValidation.isValid) {
        let errorMessage = lang ? spanishErrors.expirationDate : errors.expirationDate;
        if(moment(expirationDate, 'MM/YYYY').diff(moment.now()) < 0){
          errorMessage = lang ? spanishErrors.cardExpired : errors.cardExpired;
        }  
        setError({ ...error, expirationDate: errorMessage });
        return false;
      }
    } else if (type == "cardCode") {
      if (cardCode.length < 3 || cardCode.length > 4) {
        setError({ ...error, cardCode: lang ? spanishErrors.cardCode : errors.cardCode });
        return false;
      }
    }
    return true;
  };

  // function handle credit card Number
  const handleCardNumberChange = (value) => {
    let numberValidation = valid.number(value);
    setCardNumber(value);
    if (numberValidation.isPotentiallyValid && numberValidation.card) {
      setCardImage(cardImages[numberValidation.card.type]);
    } else {
      setCardImage(cardImages["default"]);
    }
  };

  const handleAmountChange = (value) => {
    if (value.split(".")[1] || value.split(".")[1] == "") {
      setAmount(
        value.split(".")[0] +
          "." +
          value
            .slice(value.indexOf("."), value.length)
            .replace(/[^0-9]/g, "")
            .slice(0, 2)
      );
    } else {
      setAmount(value);
    }
  };

  const handleNextStep = () => {
    let fields = ["amount", "name", "cardNumber", "expirationDate", "cardCode"];
    let isvalid = false;
    for (let i = 0; i < fields.length; i++) {
      isvalid = handleValidation(fields[i]);
      if (!isvalid) break;
    }
    if (isvalid) {
      setIsNextStep(!isNextStep);
    }
  };

  return (
    <section className="paymentform-container">
      {!isNextStep ? (
          <>
            <div className="note bg-lime-transparent-5 note-with-right-icon">
              <div className="note-icon">
                <i className="fa fa-dollar-sign"></i>
              </div>
              <div className="note-content text-right">
                <p>
                  {t('modal.payment.info-text')} <br />
                  {t('modal.payment.stay-tuned')}
                </p>
              </div>
            </div>
            <h4>{t('modal.payment.start-text')}</h4>
            <h5>
              <b>
                ${" "}
                {user &&
                  user.leaseDetails &&
                  parseInt(user.leaseDetails.Rent_Monthly_chargeTenant).toFixed(2)}
              </b>
            </h5>
            <div className="form-group m-b-10 input-group">
              <input
                type="text"
                className={`form-control form-control-lg ${
                  error.amount && "is-invalid"
                }`}
                style={{ borderRadius: "0 5px 5px 0" }}
                placeholder={t('modal.payment.enter-amount')}
                value={amount}
                onChange={(e) =>
                  handleAmountChange(e.target.value.replace(/[^0-9|^.]/g, ""))
                }
                onBlur={(e) => handleValidation("amount")}
                onFocus={(e) => setError({ ...error, amount: "" })}
              />
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fa fa-dollar-sign" />
                </span>
              </div>
              <h5 className="invalid-feedback">{error.amount}</h5>
            </div>
            <div className="form-group m-b-10">
              <input
                type="text"
                className={`form-control form-control-lg ${
                  error.name && "is-invalid"
                }`}
                placeholder={t('modal.payment.name')}
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={(e) => handleValidation("name")}
                onFocus={(e) => setError({ ...error, name: "" })}
              />
              <h5 className="invalid-feedback">{error.name}</h5>
            </div>
            <div className="form-group m-b-10 input-group">
              <input
                type="text"
                className={`form-control form-control-lg ${
                  error.cardNumber && "is-invalid"
                }`}
                style={{ borderRadius: "0 5px 5px 0" }}
                placeholder={t('modal.payment.cardno')}
                value={cardNumber}
                onChange={(e) =>
                  handleCardNumberChange(e.target.value.replace(/[^0-9]/g, ""))
                }
                onBlur={(e) => handleValidation("cardNumber")}
                onFocus={(e) => setError({ ...error, cardNumber: "" })}
              />
              <div className="input-group-prepend">
                <span className="input-group-text" style={{ height: 43 }}>
                  <i className={cardImage} />
                </span>
              </div>
              <h5 className="invalid-feedback">{error.cardNumber}</h5>
            </div>
            <div className="form-group m-b-10">
              <InputMask
                mask="99/9999"
                className={`form-control form-control-lg ${
                  error.expirationDate && "is-invalid"
                }`}
                value={expirationDate}
                placeholder="MM/YYYY"
                onBlur={(e) => handleValidation("expirationDate")}
                onChange={(e) => setExpiration(e.target.value)}
                onFocus={(e) => setError({ ...error, expirationDate: "" })}
              />
              <h5 className="invalid-feedback">{error.expirationDate}</h5>
            </div>
            <div className="form-group m-b-10">
              <input
                type="password"
                className={`form-control form-control-lg ${
                  error.cardCode && "is-invalid"
                }`}
                placeholder={t('modal.payment.cardcode')}
                value={cardCode}
                onChange={(e) =>
                  setCardCode(e.target.value.replace(/[^0-9]/g, "").slice(0, 4))
                }
                onBlur={(e) => handleValidation("cardCode")}
                onFocus={(e) => setError({ ...error, cardCode: "" })}
              />
              <h5 className="invalid-feedback">{error.cardCode}</h5>
            </div>
            <button
              type="submit"
              disabled={loading}
              className="btn btn-warning btn-block"
              onClick={handleNextStep}
            >
              {!loading ? `${t('modal.payment.next')}` : <i className="fas fa-spinner fa-pulse"></i>}
            </button>
          </>
        ) : (
          <PaymentAddress
            handleSubmit={handleSubmit}
            address={address}
            setAddress={setAddress}
            handleNextStep={handleNextStep}
            loading={loading}
          />
        )
    }
    </section>
  );
});

const mapStateToProps = ({ PAYMENT_REDUCER, USER_REDUCER }) => {
  return { loading: PAYMENT_REDUCER.loading, user: USER_REDUCER.userData };
};

const PaymentWrapper = (props) => (
  <WrapperComponent>
    <PaymentForm {...props} />
  </WrapperComponent>
);

export default connect(mapStateToProps, { MakePayment })(PaymentWrapper);

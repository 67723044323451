import React, { useState } from "react";
import { useEffect } from "react";
import styles from "./OnBoardTenant.module.css";
import states from "./statesList.js";
import Select from 'react-select';
import { useTranslation } from "react-i18next";

const HousingInformation = (props) => {
  const { form, setForm, loading } = props;
  const [errors, setErrors] = useState({});
  const [enableNext, setEnableNext] = useState(false);
  const {t} = useTranslation();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    const err = validations();
    if (Object.entries(err).length == 0) {
      setEnableNext(true);
    }
  }, [form]);

  const validateAndNavigate = (navigate = true) => {
    const tempError = validations();
    setErrors(tempError);
    if (Object.entries(tempError).length) {
      return;
    } else {
      props.handleNavigation("next", navigate);
    }
  };

  const validations = () => {
    const tempError = { ...errors };

    if (!form?.Address_Address01) {
      tempError["Address_Address01"] = "Address is required.";
    } else {
      delete tempError["Address_Address01"];
    }

    if (!form?.Address_City) {
      tempError["Address_City"] = "City is required.";
    } else {
      delete tempError["Address_City"];
    }

    if (!form?.Address_State) {
      tempError["Address_State"] = "State is required.";
    } else {
      delete tempError["Address_State"];
    }

    if (!form?.Address_PostalCode) {
      tempError["Address_PostalCode"] = "Postal Code is required.";
    } else {
      delete tempError["Address_PostalCode"];
    }

    if (!form?.Name_Lanlord) {
      tempError["Name_Lanlord"] = "Landlord name is required.";
    } else {
      delete tempError["Name_Lanlord"];
    }

    if (!form?.Phone_Landlord) {
      tempError["Phone_Landlord"] = "Landord phone is required.";
    } else if (
      form?.Phone_Landlord?.replaceAll("(", "")?.replaceAll(")", "")?.length <
      10
    ) {
      tempError["Phone_Landlord"] = "Please enter a valid phone number";
    } else {
      delete tempError["Phone_Landlord"];
    }

    if (!form?.Years_Resident || form?.Years_Resident === "-1") {
      tempError["Years_Resident"] = "Number of years is required.";
    } else {
      delete tempError["Years_Resident"];
    }

    if (!form?.Years_Resident || form?.Years_Resident == "<1") {
      if (!form?.AddressPrevious_Address01) {
        tempError["AddressPrevious_Address01"] =
          "Previous address is required.";
      } else {
        delete tempError["AddressPrevious_Address01"];
      }

      if (!form?.AddressPrevious_City) {
        tempError["AddressPrevious_City"] = "Previous city is required.";
      } else {
        delete tempError["AddressPrevious_City"];
      }

      if (!form?.AddressPrevious_State) {
        tempError["AddressPrevious_State"] = "State is required.";
      } else {
        delete tempError["AddressPrevious_State"];
      }

      if (!form?.AddressPrevious_PostalCode) {
        tempError["AddressPrevious_PostalCode"] =
          "Previous postal Code is required.";
      } else {
        delete tempError["AddressPrevious_PostalCode"];
      }

      if (!form?.Name_Landlord_Previous) {
        tempError["Name_Landlord_Previous"] =
          "Previous landlord name is required.";
      } else {
        delete tempError["Name_Landlord_Previous"];
      }

      if (!form?.Year_Residence_Previous) {
        tempError["Year_Residence_Previous"] = "Number of years are required.";
      } else {
        delete tempError["Year_Residence_Previous"];
      }

      if (!form?.Phone_Landlord_Previous) {
        tempError["Phone_Landlord_Previous"] =
          "Previous landord phone is required.";
      } else if (
        form?.Phone_Landlord_Previous?.replaceAll("(", "")?.replaceAll(")", "")
          ?.length < 10
      ) {
        tempError["Phone_Landlord_Previous"] =
          "Please enter a valid phone number";
      } else {
        delete tempError["Phone_Landlord_Previous"];
      }
    } else {
      delete tempError["AddressPrevious_Address01"];
      delete tempError["AddressPrevious_City"];
      delete tempError["AddressPrevious_PostalCode"];
      delete tempError["Name_Landlord_Previous"];
      delete tempError["Phone_Landlord_Previous"];
      delete tempError["Year_Residence_Previous"];
      delete tempError["AddressPrevious_State"];
    }

    if (!form?.HousingType) {
      tempError["HousingType"] = "Housing type is required.";
    } else {
      delete tempError["HousingType"];
    }

    if (
      (form?.HousingType == "Section B" || form?.HousingType == "TRA") &&
      !form?.CaseNumber
    ) {
      tempError["CaseNumber"] = "Case number is required.";
    } else {
      delete tempError["CaseNumber"];
    }

    if (form?.HousingType == "TRA" && !form?.Date_TRA_Application) {
      tempError["Date_TRA_Application"] = "Appointment date is required.";
    } else {
      delete tempError["Date_TRA_Application"];
    }

    if (!form?.BedroomCount || form?.BedroomCount === "-1") {
      tempError["BedroomCount"] = "Bedrooms are required.";
    } else {
      delete tempError["BedroomCount"];
    }

    return tempError;
  };

  const renderPresentAddress = () => {
    return (
      <>
        <div className="row mt-5">
          <div className="col-12">
            <h3>
              {t('tenantApp.housing_information.present_address')}{" "}
              <span className="text-secondary" style={{ fontSize: 12 }}>
              ({t('tenantApp.housing_information.two_years_history_required')})
              </span>
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.address')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors?.Address_Address01 ? "border-danger" : ""
                }`}
                placeholder={"Enter address"}
                value={form?.Address_Address01}
                onChange={(e) => {
                  setErrors({ ...errors, Address_Address01: "" });
                  setForm({ ...form, Address_Address01: e.target.value });
                }}
              />
              <span className="text-danger">{errors?.Address_Address01}</span>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.apt')}</label>
              <input
                type="text"
                className={`form-control`}
                placeholder={"Enter apertment number"}
                value={form?.Address_Address02 || ""}
                onChange={(e) => {
                  setForm({ ...form, Address_Address02: e.target.value });
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.city')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors?.Address_City ? "border-danger" : ""
                }`}
                placeholder="Enter city"
                onChange={(e) => {
                  setErrors({ ...errors, Address_City: "" });
                  setForm({ ...form, Address_City: e.target.value });
                }}
                value={form?.Address_City || ""}
              />
              <span className="text-danger">{errors?.Address_City}</span>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.state')}*</label>
              <select
                className={`form-control ${
                  errors?.Address_State ? "border-danger" : ""
                }`}
                onChange={(e) => {
                  setErrors({ ...errors, Address_State: "" });
                  setForm({ ...form, Address_State: e.target.value });
                }}
                value={form?.Address_State || ""}
              >
                <option value="-1">Select one</option>
                {states?.map((x) => (
                  <option key={x}>{x}</option>
                ))}
              </select>
              <span className="text-danger">{errors?.Address_State}</span>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.postal_code')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors?.Address_PostalCode ? "border-danger" : ""
                }`}
                placeholder="Enter postal code"
                maxLength={5}
                onChange={(e) => {
                  setErrors({ ...errors, Address_PostalCode: "" });
                  setForm({ ...form, Address_PostalCode: e.target.value });
                }}
                value={form?.Address_PostalCode || ""}
              />
              <span className="text-danger">{errors?.Address_PostalCode}</span>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.landlord')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors?.Name_Lanlord ? "border-danger" : ""
                }`}
                placeholder="Enter landlord name"
                onChange={(e) => {
                  setErrors({ ...errors, Name_Lanlord: "" });
                  setForm({ ...form, Name_Lanlord: e.target.value });
                }}
                value={form?.Name_Lanlord || ""}
              />
              <span className="text-danger">{errors?.Name_Lanlord}</span>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.landlord_phone')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors?.Phone_Landlord ? "border-danger" : ""
                }`}
                placeholder={"Enter landlord phone"}
                value={form?.Phone_Landlord || ""}
                onChange={(e) => {
                  let x = e.target.value
                    .replace(/\D/g, "")
                    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                  e.target.value = !x[2]
                    ? x[1]
                    : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
                  setErrors({ ...errors, Phone_Landlord: "" });
                  setForm({ ...form, Phone_Landlord: e.target.value });
                }}
              />
              <span className="text-danger">{errors?.Phone_Landlord}</span>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.number_of_years')}*</label>
              <Select
                defaultValue={
                  form?.Years_Resident
                    ? {
                        value: form?.Years_Resident,
                        label: form?.Years_Resident,
                      }
                    : ""
                }
                value={
                  form?.Years_Resident
                    ? {
                        value: form?.Years_Resident,
                        label: form?.Years_Resident,
                      }
                    : ""
                }
                onChange={(e) => {
                  setErrors({ ...errors, Years_Resident: "" });
                  setForm({ ...form, Years_Resident: e.value });
                }}
                options={["<1", "2", "3", "4", "5"].map((x) => {
                  return {
                    value: x,
                    label: x,
                  };
                })}
                isSearchable
                placeholder="Select one"
                className={`${
                  errors?.Years_Resident ? "border-danger" : ""
                }`}
              />
              <span className="text-danger">{errors["Years_Resident"]}</span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderPreviousAddress = () => {
    return (
      <>
        <div className="row mt-3">
          <div className="col-12">
            <h3>
            {t('tenantApp.housing_information.previous_address')}{" "}
              <span className="text-secondary" style={{ fontSize: 12 }}>
                ({t('tenantApp.housing_information.required_if_present_is_less_than_two_years')})
              </span>
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.address')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors?.AddressPrevious_Address01 ? "border-danger" : ""
                }`}
                placeholder={"Enter address"}
                onChange={(e) => {
                  setErrors({ ...errors, AddressPrevious_Address01: "" });
                  setForm({
                    ...form,
                    AddressPrevious_Address01: e.target.value,
                  });
                }}
                value={form?.AddressPrevious_Address01 || ""}
              />
              <span className="text-danger">
                {errors?.AddressPrevious_Address01}
              </span>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.apt')}</label>
              <input
                type="text"
                className={`form-control`}
                placeholder={"Enter apertment number"}
                value={form?.AddressPrevious_Address02 || ""}
                onChange={(e) => {
                  setForm({
                    ...form,
                    AddressPrevious_Address02: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.city')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors?.AddressPrevious_City ? "border-danger" : ""
                }`}
                placeholder="Enter city"
                onChange={(e) => {
                  setErrors({ ...errors, AddressPrevious_City: "" });
                  setForm({ ...form, AddressPrevious_City: e.target.value });
                }}
                value={form?.AddressPrevious_City || ""}
              />
              <span className="text-danger">
                {errors?.AddressPrevious_City}
              </span>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.state')}*</label>
              <select
                className={`form-control ${
                  errors?.AddressPrevious_State ? "border-danger" : ""
                }`}
                onChange={(e) => {
                  setErrors({ ...errors, AddressPrevious_State: "" });
                  setForm({ ...form, AddressPrevious_State: e.target.value });
                }}
                value={form?.AddressPrevious_State || ""}
              >
                <option value="-1">Select one</option>
                {states?.map((x) => (
                  <option>{x}</option>
                ))}
              </select>
              <span className="text-danger">
                {errors?.AddressPrevious_State}
              </span>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.postal_code')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors?.AddressPrevious_PostalCode ? "border-danger" : ""
                }`}
                maxLength={5}
                placeholder="Enter postal code"
                onChange={(e) => {
                  setErrors({ ...errors, AddressPrevious_PostalCode: "" });
                  setForm({
                    ...form,
                    AddressPrevious_PostalCode: e.target.value,
                  });
                }}
                value={form?.AddressPrevious_PostalCode || ""}
              />
              <span className="text-danger">
                {errors?.AddressPrevious_PostalCode}
              </span>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.landlord')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors?.Name_Landlord_Previous ? "border-danger" : ""
                }`}
                placeholder="Enter landlord name"
                onChange={(e) => {
                  setErrors({ ...errors, Name_Landlord_Previous: "" });
                  setForm({ ...form, Name_Landlord_Previous: e.target.value });
                }}
                value={form?.Name_Landlord_Previous || ""}
              />
              <span className="text-danger">
                {errors?.Name_Landlord_Previous}
              </span>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.landlord_phone')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors?.Phone_Landlord_Previous ? "border-danger" : ""
                }`}
                placeholder={"Enter landlord phone"}
                value={form?.Phone_Landlord_Previous || ""}
                onChange={(e) => {
                  let x = e.target.value
                    .replace(/\D/g, "")
                    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                  e.target.value = !x[2]
                    ? x[1]
                    : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
                  setErrors({ ...errors, Phone_Landlord_Previous: "" });
                  setForm({ ...form, Phone_Landlord_Previous: e.target.value });
                }}
              />
              <span className="text-danger">
                {errors?.Phone_Landlord_Previous}
              </span>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.number_of_years')}*</label>
              <Select
                defaultValue={
                  form?.Year_Residence_Previous
                    ? {
                        value: form?.Year_Residence_Previous,
                        label: form?.Year_Residence_Previous,
                      }
                    : ""
                }
                value={
                  form?.Year_Residence_Previous
                    ? {
                        value: form?.Year_Residence_Previous,
                        label: form?.Year_Residence_Previous,
                      }
                    : ""
                }
                onChange={(e) => {
                  setErrors({ ...errors, Year_Residence_Previous: "" });
                  setForm({ ...form, Year_Residence_Previous: e.value });
                }}
                options={["<1", "2", "3", "4", "5"].map((x) => {
                  return {
                    value: x,
                    label: x,
                  };
                })}
                isSearchable
                placeholder="Select one"
                className={`${
                  errors?.Year_Residence_Previous ? "border-danger" : ""
                }`}
              />
              <span className="text-danger">
                {errors["Year_Residence_Previous"]}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderFutureHousing = () => {
    return (
      <>
        <div className="row mt-3">
          <div className="col-12">
            <h3>{t('tenantApp.housing_information.future_housing')}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.housing_type')}*</label>
              <div className="d-flex ">
                <div className="form-check ">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="HousingType"
                    value={"market"}
                    checked={form?.HousingType === "market"}
                    onChange={(e) => {
                      setErrors({ ...errors, HousingType: "" });
                      setForm({ ...form, HousingType: "market" });
                    }}
                  />
                  <label className="form-check-label" >
                    Market
                  </label>
                </div>
                <div className="form-check ml-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="HousingType"
                    value="Section B"
                    checked={form?.HousingType === "Section B"}
                    onChange={(e) => {
                      setErrors({ ...errors, HousingType: "" });
                      setForm({ ...form, HousingType: "Section B" });
                    }}
                  />
                  <label className="form-check-label" >
                    Section 8
                  </label>
                </div>
                <div className="form-check ml-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="HousingType"
                    value="TRA"
                    checked={form?.HousingType === "TRA"}
                    onChange={(e) => {
                      setErrors({ ...errors, HousingType: "" });
                      setForm({ ...form, HousingType: "TRA" });
                    }}
                  />
                  <label className="form-check-label" >
                    TRA
                  </label>
                </div>
              </div>
              <span className="text-danger">{errors?.HousingType}</span>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.bedrooms')}*</label>
              <Select
                defaultValue={
                  form?.BedroomCount
                    ? {
                        value: form?.BedroomCount,
                        label: form?.BedroomCount,
                      }
                    : ""
                }
                value={
                  form?.BedroomCount
                    ? {
                        value: form?.BedroomCount,
                        label: form?.BedroomCount,
                      }
                    : ""
                }
                onChange={(e) => {
                  setErrors({ ...errors, BedroomCount: "" });
                  setForm({ ...form, BedroomCount: e.value });
                }}
                options={["1", "2", "3", "4", "5"].map((x) => {
                  return {
                    value: x,
                    label: x,
                  };
                })}
                isSearchable
                placeholder="Select one"
                className={`${
                  errors?.BedroomCount ? "border-danger" : ""
                }`}
              />
              <span className="text-danger">{errors?.BedroomCount}</span>
            </div>
          </div>
        </div>
        {form?.HousingType === "Section B" || form?.HousingType === "TRA" ? (
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group">
                <label className="form-label">{t('tenantApp.housing_information.case_number')}*</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors?.CaseNumber ? "border-danger" : ""
                  }`}
                  placeholder="Enter case number"
                  onChange={(e) => {
                    setErrors({ ...errors, CaseNumber: "" });
                    setForm({ ...form, CaseNumber: e.target.value });
                  }}
                  value={form?.CaseNumber || ""}
                />
                <span className="text-danger">{errors?.CaseNumber}</span>
              </div>
            </div>
            {form?.HousingType === "TRA" ? (
              <div className="col-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-label">{t('tenantApp.housing_information.appointment_date')}*</label>
                  <input
                    type="date"
                    className={`form-control ${
                      errors?.Date_TRA_Application ? "border-danger" : ""
                    }`}
                    onChange={(e) => {
                      setErrors({ ...errors, Date_TRA_Application: "" });
                      setForm({
                        ...form,
                        Date_TRA_Application: e.target.value,
                      });
                    }}
                    value={form?.Date_TRA_Application || ""}
                  />
                  <span className="text-danger">
                    {errors?.Date_TRA_Application}
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </>
    );
  };

  const renderSocialWorker = () => {
    return (
      <>
        <div className="row mt-3">
          <div className="col-12">
            <h3>Social Worker</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.email')}</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter email"
                value={form?.SocialWorker_Email || ""}
                onChange={(e) =>
                  setForm({ ...form, SocialWorker_Email: e.target.value })
                }
              />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.Name')}</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter name"
                value={form?.SocialWorker_Name || ""}
                onChange={(e) =>
                  setForm({ ...form, SocialWorker_Name: e.target.value })
                }
              />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.housing_information.phone')}</label>
              <input
                type="text"
                placeholder="Enter phone"
                className="form-control"
                value={form?.SocialWorker_Phone || ""}
                onChange={(e) => {
                  let x = e.target.value
                    .replace(/\D/g, "")
                    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                  e.target.value = !x[2]
                    ? x[1]
                    : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
                  setForm({ ...form, SocialWorker_Phone: e.target.value });
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderNavigationButton = () => {
    return (
      <>
        <div className="row mt-4 justify-content-end">
          <div className="col-4 col-lg-2">
            <button
              type="button"
              className="btn btn-secondary btn-block"
              onClick={() => {
                props.handleNavigation("previous");
              }}
              disabled={loading}
            >
              {t('tenantApp.navigation.previous')}
              {loading ? (
                <span className="spinner-border spinner-border-sm float-right text-black"></span>
              ) : null}
            </button>
          </div>
          <div className="col-4 col-lg-2">
            <button
              type="button"
              onClick={() => {
                validateAndNavigate(false);
              }}
              className="btn btn-success btn-block"
              disabled={loading}
            >
              {t('tenantApp.navigation.save')}
              {loading ? (
                <span className="spinner-border spinner-border-sm float-right text-black"></span>
              ) : null}
            </button>
          </div>
          <div className="col-4 col-lg-2">
            <button
              type="submit"
              className="btn btn-warning btn-block"
              disabled={loading || !enableNext}
            >
              {t('tenantApp.navigation.next')}
              {loading ? (
                <span className="spinner-border spinner-border-sm float-right"></span>
              ) : null}
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        validateAndNavigate(true);
      }}
    >
      {renderPresentAddress()}
      {!form?.Years_Resident || form?.Years_Resident == "<1"
        ? renderPreviousAddress()
        : null}
      {renderFutureHousing()}
      {renderSocialWorker()}
      {renderNavigationButton()}
    </form>
  );
};

export default HousingInformation;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import states from "./statesList";
import { useTranslation } from "react-i18next";
import Switch from 'react-switch';

const PersonalInformation = (props) => {
  const { form, setForm, loading } = props;
  const [errors, setErrors] = useState({});
  const [enableNext, setEnableNext] = useState(false);
  const { t } = useTranslation();
  const { carInfo } = useSelector(({ PARKING_REDUCER }) => PARKING_REDUCER);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    const err = validations();
    if (Object.entries(err).length == 0) {
      setEnableNext(true);
    }
  }, [form]);

  const validateAndNavigate = (navigate = true) => {
    const tempError = validations();
    setErrors(tempError);
    if (Object.entries(tempError).length) {
      return;
    } else {
      props.handleNavigation("next", navigate);
    }
  };

  const validations = () => {
    const tempError = { ...errors };
    if (!form?.Name_First) {
      tempError["Name_First"] = "First name is required.";
    } else {
      delete tempError["Name_First"];
    }

    if (!form?.Name_Last) {
      tempError["Name_Last"] = "Last name is required.";
    } else {
      delete tempError["Name_Last"];
    }

    if (!form?.Email) {
      tempError["Email"] = "Email is required.";
    } else if (!form?.Email?.includes("@") || !form?.Email?.includes(".")) {
      tempError["Email"] = "Please enter valid email.";
    } else {
      delete tempError["Email"];
    }

    if (!form?.Phone) {
      tempError["Phone"] = "Phone is required.";
    } else if (
      form?.Phone?.replaceAll("(", "")?.replaceAll(")", "")?.length < 10
    ) {
      tempError["Phone"] = "Please enter a valid phone number";
    } else {
      delete tempError["Phone"];
    }

    if (!form?.Birthdate_ManualEnter) {
      tempError["Birthdate_ManualEnter"] = "Please enter date of birth.";
    } else {
      delete tempError["Birthdate_ManualEnter"];
    }

    if (!form?.SocialSecurity) {
      tempError["SocialSecurity"] = "Social security number is required.";
    } else {
      delete tempError["SocialSecurity"];
    }

    if (form?.Car && form?.Car === "Yes") {
      if (!form?.CarMake) {
        tempError["CarMake"] = "Car make is required.";
      } else {
        delete tempError["CarMake"];
      }

      if (!form?.CarModel) {
        tempError["CarModel"] = "Car model is required.";
      } else {
        delete tempError["CarModel"];
      }

      if (!form?.CarYear) {
        tempError["CarYear"] = "Car year is required.";
      } else if (
        form?.Car &&
        (form?.CarYear?.length < 2 || form?.CarYear?.length > 4)
      ) {
        tempError["CarYear"] = "Please enter a valid year.";
      } else {
        delete tempError["CarYear"];
      }

      if (!form?.CarColor) {
        tempError["CarColor"] = "Car Color is required.";
      } else {
        delete tempError["CarColor"];
      }

      if (!form?.CarLicencePlate) {
        tempError["CarLicencePlate"] = "License plate is required.";
      } else {
        delete tempError["CarLicencePlate"];
      }

      if (!form?.CarLicenceState || form?.CarLicenceState == "-1") {
        tempError["CarLicenceState"] = "License plate state is required.";
      } else {
        delete tempError["CarLicenceState"];
      }
    } else {
      delete tempError["CarMake"];
      delete tempError["CarModel"];
      delete tempError["CarYear"];
      delete tempError["CarColor"];
      delete tempError["CarLicencePlate"];
      delete tempError["CarLicenceState"];
    }

    if (!form?.Income || form?.Income === "-1") {
      tempError["Income"] = "Yearly income is required.";
    } else {
      delete tempError["Income"];
    }

    if (!form?.MaritalStatus || form?.MaritalStatus === "-1") {
      tempError["MaritalStatus"] = "Marital status is required.";
    } else {
      delete tempError["MaritalStatus"];
    }

    if (!form?.howDidYouHearAboutUs || form?.howDidYouHearAboutUs === "-1") {
      tempError["howDidYouHearAboutUs"] = "Field is required.";
    } else {
      delete tempError["howDidYouHearAboutUs"];
    }

    return tempError;
  };

  const renderPersonalDetails = () => {
    return (
      <>
        <div className="row mt-5">
          <div className="col-12">
            <h3>
              {t("tenantApp.personal_information.personal_details.title")}
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label className="form-label">
                {t(
                  "tenantApp.personal_information.personal_details.first_name"
                )}
                *
              </label>
              <input
                type="text"
                className={`form-control ${
                  errors?.Name_First ? "border-danger" : ""
                }`}
                placeholder={"Enter first name"}
                value={form?.Name_First || ""}
                onChange={(e) => {
                  setErrors({ ...errors, Name_First: "" });
                  setForm({ ...form, Name_First: e.target.value });
                }}
              />
              <span className="text-danger">{errors?.Name_First}</span>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label className="form-label">
                {t("tenantApp.personal_information.personal_details.last_name")}
                *
              </label>
              <input
                type="text"
                className={`form-control ${
                  errors?.Name_Last ? "border-danger" : ""
                }`}
                placeholder={"Enter last name"}
                value={form?.Name_Last || ""}
                onChange={(e) => {
                  setErrors({ ...errors, Name_Last: "" });
                  setForm({ ...form, Name_Last: e.target.value });
                }}
              />
              <span className="text-danger">{errors?.Name_Last}</span>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label className="form-label">
                {t("tenantApp.personal_information.personal_details.email")}*
              </label>
              <input
                type="email"
                className={`form-control ${
                  errors?.Email ? "border-danger" : ""
                }`}
                placeholder={"Enter Email"}
                value={form?.Email || ""}
                onChange={(e) => {
                  setErrors({ ...errors, Email: "" });
                  setForm({ ...form, Email: e.target.value });
                }}
              />
              <span className="text-danger">{errors?.Email}</span>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label className="form-label">
                {t("tenantApp.personal_information.personal_details.phone")}*
              </label>
              <input
                type="text"
                className={`form-control ${
                  errors?.Phone ? "border-danger" : ""
                }`}
                placeholder={"Enter phone"}
                value={form?.Phone}
                onChange={(e) => {
                  let x = e.target.value
                    .replace(/\D/g, "")
                    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                  e.target.value = !x[2]
                    ? x[1]
                    : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
                  setErrors({ ...errors, Phone: "" });
                  setForm({ ...form, Phone: e.target.value });
                }}
              />
              <span className="text-danger">{errors?.Phone}</span>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label className="form-label">
                {t("tenantApp.personal_information.personal_details.birthdate")}
                *
              </label>
              <input
                type="date"
                data-date-format="MM/DD/YYYY"
                className={`form-control ${
                  errors?.Birthdate_ManualEnter ? "border-danger" : ""
                }`}
                placeholder={"MM/DD/YYYY"}
                value={form?.Birthdate_ManualEnter || ""}
                onChange={(e) => {
                  setErrors({ ...errors, Birthdate_ManualEnter: "" });
                  setForm({ ...form, Birthdate_ManualEnter: e.target.value });
                }}
              />
              <span className="text-danger">
                {errors?.Birthdate_ManualEnter}
              </span>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label className="form-label">
                {t(
                  "tenantApp.personal_information.personal_details.social_security_number"
                )}
                *
              </label>
              <input
                type="text"
                className={`form-control ${
                  errors?.SocialSecurity ? "border-danger" : ""
                }`}
                placeholder={"Enter social security number"}
                value={form?.SocialSecurity || ""}
                onChange={(e) => {
                  let x = e.target.value
                    .replace(/\D/g, "")
                    .match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
                  e.target.value = !x[2]
                    ? x[1]
                    : "" + x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
                  setErrors({ ...errors, SocialSecurity: "" });
                  setForm({ ...form, SocialSecurity: e.target.value });
                }}
              />
              <span className="text-danger">{errors?.SocialSecurity}</span>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label className="form-label">
                {t(
                  "tenantApp.personal_information.personal_details.divers_license_state"
                )}
              </label>
              <Select
                defaultValue={
                  form?.DriversLicenceState
                    ? {
                        value: form?.DriversLicenceState,
                        label: form?.DriversLicenceState,
                      }
                    : ""
                }
                onChange={(e) => {
                  setForm({ ...form, DriversLicenceState: e.value });
                }}
                options={states.map((x) => {
                  return {
                    value: x,
                    label: x,
                  };
                })}
                isSearchable
                placeholder="Select one"
              />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label className="form-label">
                {t(
                  "tenantApp.personal_information.personal_details.divers_license"
                )}
              </label>
              <input
                type="text"
                className={`form-control`}
                placeholder={"Enter Diver's license number"}
                value={form?.DriversLicenceNumber || ""}
                onChange={(e) => {
                  setForm({ ...form, DriversLicenceNumber: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label className="form-label">
                {t(
                  "tenantApp.personal_information.personal_details.yearly_income"
                )}
                *
              </label>
              <Select
                defaultValue={
                  form?.Income
                    ? {
                        value: form?.Income,
                        label: form?.Income,
                      }
                    : ""
                }
                value={
                  form?.Income
                    ? {
                        value: form?.Income,
                        label: form?.Income,
                      }
                    : ""
                }
                onChange={(e) => {
                  setErrors({ ...errors, Income: "" });
                  setForm({ ...form, Income: e.value });
                }}
                options={[
                  "0-15,000",
                  "15,000-30,000",
                  "30,000-45,000",
                  "45,000-60,000",
                  "60,000-75,000",
                  "+75,000",
                ].map((x) => {
                  return {
                    value: x,
                    label: x,
                  };
                })}
                isSearchable
                placeholder="Select one"
                className={`${errors?.Income ? "border-danger" : ""}`}
              />
              <span className="text-danger">{errors?.Income}</span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderCars = () => {
    return (
      <>
        <div className="row mt-3">
          <div className="col-12 d-flex">
            <h3>
              {t("tenantApp.personal_information.car_details.do_you_have_car")}
             </h3>
             <Switch onChange={(event)=> 
                setForm({ ...form, Car: event ? "Yes" : "No"})
               } checked={form?.Car == "Yes" || false} 
               className="ml-5"
               checkedIcon={<div style={{width: 28, height: 28, display:'flex', alignItems: 'center', justifyContent: 'center'}}><span className="text-white">Yes</span></div>}
               uncheckedIcon={<div style={{width: 28, height: 28, display:'flex', alignItems: 'center', justifyContent: 'center'}}><span className="text-white">No</span></div>}
               />
            </div>
        </div>
        {form?.Car == "Yes" ? (
          <div className="row">
            <div className="col-12 col-sm-6 ">
              <div className="form-group">
                <label className="form-label">
                  {t("tenantApp.personal_information.car_details.make")}*
                </label>
                <Select
                  defaultValue={
                    form?.CarMake
                      ? {
                          value: form?.CarMake,
                          label: form?.CarMake,
                        }
                      : ""
                  }
                  onChange={(e) => {
                    setErrors({ ...errors, CarMake: "" });
                    setForm({ ...form, CarMake: e.value });
                  }}
                  options={carInfo.map((x) => {
                    return {
                      value: x.Make_Name.trim(),
                      label: x.Make_Name.trim(),
                    };
                  })}
                  isSearchable
                  placeholder="Select one"
                  className={`${errors?.CarMake ? "border-danger" : ""}`}
                />
                <span className="text-danger">{errors?.CarMake}</span>
              </div>
            </div>
            <div className="col-12 col-sm-6 ">
              <div className="form-group">
                <label className="form-label">
                  {t("tenantApp.personal_information.car_details.model")}*
                </label>
                <Select
                  defaultValue={
                    form?.CarModel
                      ? { value: form?.CarModel, label: form?.CarModel }
                      : ""
                  }
                  onChange={(e) => {
                    setErrors({ ...errors, CarModel: "" });
                    setForm({ ...form, CarModel: e.value });
                  }}
                  options={carInfo
                    .find((x) => x.Make_Name.trim() === form.CarMake)
                    ?.models?.map((y) => {
                      return { value: y.Model_Name, label: y.Model_Name };
                    })}
                  isSearchable
                  placeholder="Select one"
                  className={`${errors?.CarModel ? "border-danger" : ""}`}
                />
                <span className="text-danger">{errors?.CarModel}</span>
              </div>
            </div>
            <div className="col-12 col-sm-6 ">
              <div className="form-group">
                <label className="form-label">
                  {t("tenantApp.personal_information.car_details.color")}*
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors?.CarColor ? "border-danger" : ""
                  }`}
                  placeholder={"Enter car color"}
                  value={form?.CarColor || ""}
                  onChange={(e) => {
                    setErrors({ ...errors, CarColor: "" });
                    setForm({ ...form, CarColor: e.target.value });
                  }}
                />
                <span className="text-danger">{errors?.CarColor}</span>
              </div>
            </div>
            <div className="col-12 col-sm-6 ">
              <div className="form-group">
                <label className="form-label">Year*</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors?.CarYear ? "border-danger" : ""
                  }`}
                  placeholder={"Enter year"}
                  value={form?.CarYear || ""}
                  minLength={2}
                  maxLength={4}
                  onChange={(e) => {
                    let x = e.target.value.replace(/\D/g, "");
                    e.target.value = x;
                    setErrors({ ...errors, CarYear: "" });
                    setForm({ ...form, CarYear: e.target.value });
                  }}
                />
                <span className="text-danger">{errors?.CarYear}</span>
              </div>
            </div>
            <div className="col-12 col-sm-6 ">
              <div className="form-group">
                <label className="form-label">
                  {t(
                    "tenantApp.personal_information.car_details.license_plate"
                  )}
                  *
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors?.CarLicencePlate ? "border-danger" : ""
                  }`}
                  placeholder={"Enter license plate"}
                  value={form?.CarLicencePlate || ""}
                  onChange={(e) => {
                    setErrors({ ...errors, CarLicencePlate: "" });
                    setForm({ ...form, CarLicencePlate: e.target.value });
                  }}
                />
                <span className="text-danger">{errors?.CarLicencePlate}</span>
              </div>
            </div>
            <div className="col-12 col-sm-6 ">
              <div className="form-group">
                <label className="form-label">
                  {t(
                    "tenantApp.personal_information.car_details.license_plate_state"
                  )}
                  *
                </label>
                <Select
                  defaultValue={
                    form?.CarLicenceState
                      ? {
                          value: form?.CarLicenceState,
                          label: form?.CarLicenceState,
                        }
                      : ""
                  }
                  onChange={(e) => {
                    setErrors({ ...errors, CarLicenceState: "" });
                    setForm({ ...form, CarLicenceState: e.value });
                  }}
                  options={states.map((x) => {
                    return {
                      value: x,
                      label: x,
                    };
                  })}
                  isSearchable
                  placeholder="Select one"
                  className={`${
                    errors?.CarLicenceState ? "border-danger" : ""
                  }`}
                />
                <span className="text-danger">{errors?.CarLicenceState}</span>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const renderMaritalStatus = () => {
    return (
      <>
        <div className="row mt-3">
          <div className="col-12">
            <h3>{t("tenantApp.personal_information.marital_details.title")}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-4">
            <div className="form-group">
              <label className="form-label">
                {t(
                  "tenantApp.personal_information.marital_details.marital_status"
                )}
                *
              </label>

              <Select
                defaultValue={
                  form?.MaritalStatus
                    ? {
                        value: form?.MaritalStatus,
                        label: form?.MaritalStatus,
                      }
                    : ""
                }
                onChange={(e) => {
                  setErrors({ ...errors, MaritalStatus: "" });
                  setForm({ ...form, MaritalStatus: e.value });
                }}
                options={["Unmarried", "Married"].map((x) => {
                  return {
                    value: x,
                    label: x,
                  };
                })}
                isSearchable
                placeholder="Select one"
                className={`${errors?.MaritalStatus ? "border-danger" : ""}`}
              />
              <span className="text-danger">{errors?.MaritalStatus}</span>
            </div>
          </div>
          {form?.MaritalStatus == "Married" ? (
            <>
              <div className="col-12 col-sm-4">
                <div className="form-group">
                  <label className="form-label">
                    {t(
                      "tenantApp.personal_information.marital_details.spouse_name"
                    )}
                  </label>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={"Enter spouse name"}
                    value={form?.Name_Spouse || ""}
                    onChange={(e) => {
                      setForm({ ...form, Name_Spouse: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-4">
                <div className="form-group">
                  <label className="form-label">
                    {t(
                      "tenantApp.personal_information.marital_details.children_count"
                    )}
                  </label>
                  <input
                    type="number"
                    className={`form-control`}
                    placeholder={""}
                    min="0"
                    value={form?.ChildrenCount || 0}
                    onChange={(e) => {
                      setForm({ ...form, ChildrenCount: e.target.value });
                    }}
                  />
                </div>
              </div>
            </>
          ) : null}
        </div>
      </>
    );
  };

  const renderOtherResidents = () => {
    return (
      <>
        <div className="row mt-3">
          <div className="col-12">
            <h3>
              {t(
                "tenantApp.personal_information.other_residents.other_residents_excluding_chilg"
              )}
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <label className="form-label">
              {t("tenantApp.personal_information.other_residents.fullname")}
            </label>
          </div>
          <div className="col-4">
            <label className="form-label">
              {t("tenantApp.personal_information.other_residents.relationship")}
            </label>
          </div>
          <div className="col-4">
            <label className="form-label">
              {t("tenantApp.personal_information.other_residents.age")}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <input
                type="text"
                className={`form-control`}
                placeholder={"Enter fullname"}
                value={form?.["OtherResidents01(1)"] || ""}
                onChange={(e) => {
                  setForm({ ...form, "OtherResidents01(1)": e.target.value });
                }}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <input
                type="text"
                className={`form-control`}
                placeholder={"Enter relationship with resident"}
                value={form?.["OtherResidentRelationship(1)"] || ""}
                onChange={(e) => {
                  setForm({
                    ...form,
                    "OtherResidentRelationship(1)": e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <input
                type="number"
                className={`form-control`}
                placeholder={"Enter age of resident"}
                min="1"
                value={form?.["OtherResidentAge(1)"] || ""}
                onChange={(e) => {
                  setForm({ ...form, "OtherResidentAge(1)": e.target.value });
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <input
                type="text"
                className={`form-control`}
                placeholder={"Enter fullname"}
                value={form?.["OtherResidents01(2)"] || ""}
                onChange={(e) => {
                  setForm({ ...form, "OtherResidents01(2)": e.target.value });
                }}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <input
                type="text"
                className={`form-control`}
                placeholder={"Enter relationship with resident"}
                value={form?.["OtherResidentRelationship(2)"] || ""}
                onChange={(e) => {
                  setForm({
                    ...form,
                    "OtherResidentRelationship(2)": e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <input
                type="number"
                className={`form-control`}
                placeholder={"Enter age of resident"}
                min="1"
                value={form?.["OtherResidentAge(2)"] || ""}
                onChange={(e) => {
                  setForm({ ...form, "OtherResidentAge(2)": e.target.value });
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <input
                type="text"
                className={`form-control`}
                placeholder={"Enter fullname"}
                value={form?.["OtherResidents01(3)"] || ""}
                onChange={(e) => {
                  setForm({ ...form, "OtherResidents01(3)": e.target.value });
                }}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <input
                type="text"
                className={`form-control`}
                placeholder={"Enter relationship with resident"}
                value={form?.["OtherResidentRelationship(3)"] || ""}
                onChange={(e) => {
                  setForm({
                    ...form,
                    "OtherResidentRelationship(3)": e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <input
                type="number"
                className={`form-control`}
                placeholder={"Enter age of resident"}
                min="1"
                value={form?.["OtherResidentAge(3)"] || ""}
                onChange={(e) => {
                  setForm({ ...form, "OtherResidentAge(3)": e.target.value });
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderNavigationButton = () => {
    return (
      <>
        <div className="row mt-4 justify-content-end">
          <div className="col-6 col-sm-6 col-md-4 col-lg-2">
            <button
              type="button"
              onClick={() => {
                validateAndNavigate(false);
              }}
              className="btn btn-success btn-block"
              disabled={loading}
            >
              {t("tenantApp.navigation.save")}
              {loading ? (
                <span className="spinner-border spinner-border-sm float-right text-black"></span>
              ) : null}
            </button>
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-2">
            <button
              type="submit"
              className="btn btn-warning btn-block"
              disabled={loading || !enableNext}
            >
              {t("tenantApp.navigation.next")}
              {loading ? (
                <span className="spinner-border spinner-border-sm float-right"></span>
              ) : null}
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        validateAndNavigate(true);
      }}
    >
      {renderPersonalDetails()}
      {renderCars()}
      {renderMaritalStatus()}
      {renderOtherResidents()}
      <div className="row mt-3">
        <div className="col-12 col-sm-6">
          <div className="form-group">
            <label className="form-label">
              {t(
                "tenantApp.personal_information.personal_details.how_did_you_hear_about_us"
              )}
            </label>
            <Select
              defaultValue={
                form?.howDidYouHearAboutUs
                  ? {
                      value: form?.howDidYouHearAboutUs,
                      label: form?.howDidYouHearAboutUs,
                    }
                  : ""
              }
              onChange={(e) => {
                setForm({ ...form, howDidYouHearAboutUs: e.value });
              }}
              options={[
                "Ania Website",
                "Walk, in",
                "Referral",
                "Facebook",
                "Instagram",
                "Craigslist",
                "Paterson Program",
                "Family/friend",
                "Sign on Building",
              ].map((x) => {
                return {
                  value: x,
                  label: x,
                };
              })}
              isSearchable
              placeholder="Select one"
            />
            <span className="text-danger">{errors?.howDidYouHearAboutUs}</span>
          </div>
        </div>
      </div>
      {renderNavigationButton()}
    </form>
  );
};

export default PersonalInformation;

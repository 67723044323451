import React, { useState } from "react";
import { useEffect } from "react";
import styles from "./OnBoardTenant.module.css";
import { useTranslation } from "react-i18next";

const References = (props) => {
  const { form, setForm, loading } = props;
  const [errors, setErrors] = useState({});
  const [enableNext, setEnableNext] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    const err = validations();
    if (Object.entries(err).length == 0) {
      setEnableNext(true);
    }
  }, [form]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const validateAndNavigate = (navigate) => {
    const tempError = validations();

    setErrors(tempError);
    if (Object.entries(tempError).length) {
      return;
    } else {
      props.handleNavigation("next", navigate);
    }
  };

  const validations = () => {
    const tempError = { ...errors };

    if (!form?.Emergency_Name) {
      tempError["Emergency_Name"] = "Emergency name is required";
    } else {
      delete tempError["Emergency_Name"];
    }

    if (!form?.Emergency_Phone) {
      tempError["Emergency_Phone"] = "Emergency phone is required";
    } else if (
      form &&
      form["Emergency_Phone"]?.replaceAll("(", "")?.replaceAll(")", "")
        ?.length < 10
    ) {
      tempError["Emergency_Phone"] = "Please enter a valid phone";
    } else {
      delete tempError["Emergency_Phone"];
    }

    if (!form?.Emergency_Relationship) {
      tempError["Emergency_Relationship"] = "Relationship is required";
    } else {
      delete tempError["Emergency_Relationship"];
    }

    if (!form["Refences_Name(1)"]) {
      tempError["Refences_Name(1)"] = "Name is required";
    } else {
      delete tempError["Refences_Name(1)"];
    }

    if (!form["References_Phone(1)"]) {
      tempError["References_Phone(1)"] = "Phone is required";
    } else if (
      form &&
      form["References_Phone(1)"]?.replaceAll("(", "")?.replaceAll(")", "")
        ?.length < 10
    ) {
      tempError["References_Phone(1)"] = "Please enter a valid phone";
    } else {
      delete tempError["References_Phone(1)"];
    }

    if (!form["References_Relationship(1)"]) {
      tempError["References_Relationship(1)"] = "Relationship is required";
    } else {
      delete tempError["References_Relationship(1)"];
    }

    if (!form["Refences_Name(2)"]) {
      tempError["Refences_Name(2)"] = "Name is required";
    } else {
      delete tempError["Refences_Name(2)"];
    }

    if (!form["References_Phone(2)"]) {
      tempError["References_Phone(2)"] = "Phone is required";
    } else if (
      form &&
      form["References_Phone(2)"]?.replaceAll("(", "")?.replaceAll(")", "")
        ?.length < 10
    ) {
      tempError["References_Phone(2)"] = "Please enter a valid phone";
    } else {
      delete tempError["References_Phone(2)"];
    }

    if (!form["References_Relationship(2)"]) {
      tempError["References_Relationship(2)"] = "Relationship is required";
    } else {
      delete tempError["References_Relationship(2)"];
    }

    if (!form["Refences_Name(3)"]) {
      tempError["Refences_Name(3)"] = "Name is required";
    } else {
      delete tempError["Refences_Name(3)"];
    }

    if (!form["References_Phone(3)"]) {
      tempError["References_Phone(3)"] = "Phone is required";
    } else if (
      form &&
      form["References_Phone(3)"]?.replaceAll("(", "")?.replaceAll(")", "")
        ?.length < 10
    ) {
      tempError["References_Phone(3)"] = "Please enter a valid phone";
    } else {
      delete tempError["References_Phone(3)"];
    }

    if (!form["References_Relationship(3)"]) {
      tempError["References_Relationship(3)"] = "Relationship is required";
    } else {
      delete tempError["References_Relationship(3)"];
    }
    return tempError;
  };

  const renderEmergencyDetails = () => {
    return (
      <>
        <div className="row mt-5">
          <div className="col-12">
            <h3>{t('tenantApp.references_.emergency_contact_details')}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.references_.name')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors?.Emergency_Name ? "border-danger" : ""
                }`}
                placeholder="Enter name"
                value={form?.Emergency_Name || ""}
                onChange={(e) => {
                  setErrors({ ...errors, Emergency_Name: "" });
                  setForm({ ...form, Emergency_Name: e.target.value });
                }}
              />
              <span className="text-danger">{errors?.Emergency_Name}</span>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.references_.phone')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors?.Emergency_Phone ? "border-danger" : ""
                }`}
                placeholder="Enter phone"
                onChange={(e) => {
                  let x = e.target.value
                    .replace(/\D/g, "")
                    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                  e.target.value = !x[2]
                    ? x[1]
                    : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
                  setErrors({ ...errors, Emergency_Phone: "" });
                  setForm({ ...form, Emergency_Phone: e.target.value });
                }}
                value={form?.Emergency_Phone || ""}
              />
              <span className="text-danger">{errors?.Emergency_Phone}</span>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.references_.relationship')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors?.Emergency_Relationship ? "border-danger" : ""
                }`}
                placeholder="Enter relationship"
                value={form?.Emergency_Relationship || ""}
                onChange={(e) => {
                  setErrors({ ...errors, Emergency_Relationship: "" });
                  setForm({ ...form, Emergency_Relationship: e.target.value });
                }}
              />
              <span className="text-danger">
                {errors?.Emergency_Relationship}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderReferences = () => {
    return (
      <>
        <div className="row mt-3">
          <div className="col-12">
            <h3>{t('tenantApp.references')}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.references_.name')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors["Refences_Name(1)"] ? "border-danger" : ""
                }`}
                placeholder="Enter name"
                value={form["Refences_Name(1)"] || ""}
                onChange={(e) => {
                  setErrors({ ...errors, "Refences_Name(1)": "" });
                  setForm({ ...form, "Refences_Name(1)": e.target.value });
                }}
              />
              <span className="text-danger">{errors["Refences_Name(1)"]}</span>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.references_.phone')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors["References_Phone(1)"] ? "border-danger" : ""
                }`}
                placeholder="Enter phone"
                onChange={(e) => {
                  let x = e.target.value
                    .replace(/\D/g, "")
                    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                  e.target.value = !x[2]
                    ? x[1]
                    : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
                  setErrors({ ...errors, "References_Phone(1)": "" });
                  setForm({ ...form, "References_Phone(1)": e.target.value });
                }}
                value={form["References_Phone(1)"] || ""}
              />
              <span className="text-danger">
                {errors["References_Phone(1)"]}
              </span>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.references_.relationship')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors["References_Relationship(1)"] ? "border-danger" : ""
                }`}
                placeholder="Enter relationship"
                value={form["References_Relationship(1)"] || ""}
                onChange={(e) => {
                  setErrors({ ...errors, "References_Relationship(1)": "" });
                  setForm({
                    ...form,
                    "References_Relationship(1)": e.target.value,
                  });
                }}
              />
              <span className="text-danger">
                {errors["References_Relationship(1)"]}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.references_.name')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors["Refences_Name(2)"] ? "border-danger" : ""
                }`}
                placeholder="Enter name"
                value={form["Refences_Name(2)"] || ""}
                onChange={(e) => {
                  setErrors({ ...errors, "Refences_Name(2)": "" });
                  setForm({ ...form, "Refences_Name(2)": e.target.value });
                }}
              />
              <span className="text-danger">{errors["Refences_Name(2)"]}</span>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.references_.phone')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors["References_Phone(2)"] ? "border-danger" : ""
                }`}
                placeholder="Enter phone"
                onChange={(e) => {
                  let x = e.target.value
                    .replace(/\D/g, "")
                    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                  e.target.value = !x[2]
                    ? x[1]
                    : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
                  setErrors({ ...errors, "References_Phone(2)": "" });
                  setForm({ ...form, "References_Phone(2)": e.target.value });
                }}
                value={form["References_Phone(2)"] || ""}
              />
              <span className="text-danger">
                {errors["References_Phone(2)"]}
              </span>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.references_.relationship')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors["References_Relationship(2)"] ? "border-danger" : ""
                }`}
                placeholder="Enter relationship"
                value={form["References_Relationship(2)"] || ""}
                onChange={(e) => {
                  setErrors({ ...errors, "References_Relationship(2)": "" });
                  setForm({
                    ...form,
                    "References_Relationship(2)": e.target.value,
                  });
                }}
              />
              <span className="text-danger">
                {errors["References_Relationship(2)"]}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.references_.name')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors["Refences_Name(3)"] ? "border-danger" : ""
                }`}
                placeholder="Enter name"
                value={form["Refences_Name(3)"] || ""}
                onChange={(e) => {
                  setErrors({ ...errors, "Refences_Name(3)": "" });
                  setForm({ ...form, "Refences_Name(3)": e.target.value });
                }}
              />
              <span className="text-danger">{errors["Refences_Name(3)"]}</span>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.references_.phone')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors["References_Phone(3)"] ? "border-danger" : ""
                }`}
                placeholder="Enter phone"
                onChange={(e) => {
                  let x = e.target.value
                    .replace(/\D/g, "")
                    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                  e.target.value = !x[2]
                    ? x[1]
                    : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
                  setErrors({ ...errors, "References_Phone(3)": "" });
                  setForm({ ...form, "References_Phone(3)": e.target.value });
                }}
                value={form["References_Phone(3)"] || ""}
              />
              <span className="text-danger">
                {errors["References_Phone(3)"]}
              </span>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <div className="form-group">
              <label className="form-label">{t('tenantApp.references_.relationship')}*</label>
              <input
                type="text"
                className={`form-control ${
                  errors["References_Relationship(3)"] ? "border-danger" : ""
                }`}
                placeholder="Enter relationship"
                value={form["References_Relationship(3)"] || ""}
                onChange={(e) => {
                  setErrors({ ...errors, "References_Relationship(3)": "" });
                  setForm({
                    ...form,
                    "References_Relationship(3)": e.target.value,
                  });
                }}
              />
              <span className="text-danger">
                {errors["References_Relationship(3)"]}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderNavigationButton = () => {
    return (
      <>
        <div className="row mt-4 justify-content-end">
          <div className="col-4 col-lg-2">
            <button
              type="button"
              className="btn btn-secondary btn-block"
              onClick={() => {
                props.handleNavigation("previous");
              }}
              disabled={loading}
            >
              {t('tenantApp.navigation.previous')}
              {loading ? (
                <span className="spinner-border spinner-border-sm float-right text-black"></span>
              ) : null}
            </button>
          </div>
          <div className="col-4 col-lg-2">
            <button
              type="button"
              onClick={() => {
                validateAndNavigate(false);
              }}
              className="btn btn-success btn-block"
              disabled={loading}
            >
              {t('tenantApp.navigation.save')}
              {loading ? (
                <span className="spinner-border spinner-border-sm float-right"></span>
              ) : null}
            </button>
          </div>
          <div className="col-4 col-lg-2">
            <button
              type="submit"
              className="btn btn-warning btn-block"
              disabled={loading || !enableNext}
            >
              {t('tenantApp.navigation.next')}
              {loading ? (
                <span className="spinner-border spinner-border-sm float-right"></span>
              ) : null}
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        validateAndNavigate(true);
      }}
    >
      {renderEmergencyDetails()}
      {renderReferences()}
      {renderNavigationButton()}
    </form>
  );
};

export default References;

import React, { memo, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
} from "reactstrap";
import {
  ViewPayment,
  clearPaymentDetails,
  cancelRecurringPayment,
  GetAllPayment
} from "../../redux/actions/payments";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import RecurringForm from "./recurringBillingForm";
import PaymentForm from "./paymentForm.jsx";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import CancelRecurringList from '../payments/cancelRecurringList';
import { useTranslation } from "react-i18next";
import ReactPaginate from 'react-paginate';


const PaymentsList = memo(
  ({
    payments = [],
    GetAllPayment,
    user,
    paymentDetail,
    ViewPayment,
    clearPaymentDetails,
    cancelRecurringPayment,
    Leases,
    appLoading
  }) => {
    const [loading, setLoading] = useState(false);
    const [modalBody, setModalBody] = useState("");
    const [headerTitle, setHeaderTitle] = useState("");
    const [modalDialog, setModalDialog] = useState(false);
    const [download, setDownload] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [openPaymentAlert, setOpenPaymentAlert] = useState(false);
    const [transactionResponse, setTransactionResponse] = useState("");
    const myRef = useRef();
    const {t} = useTranslation();
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
      if (!payments.length && Leases && Leases.length) {
        GetAllPayment(onSuccess,page);
        setLoading(true);
      }
    }, []);

    useEffect(() => {
      if(payments.length)
      {
        setPageCount(Math.ceil(payments[0].totalCount / 20))
  
      }
    }, [payments]);


    const onSuccess = () => {
      setLoading(false);
    };

    const toggleModal = (value) => {
      // e && e.stopPropagation();
      if (value) {
        setModalBody(value);
      }
      setModalDialog(!modalDialog);
    };

    const closeModal = (bool) => {
      if (bool) {
        GetAllPayment(page);

      }
      setModalDialog(false);
    };

    useEffect(() => {
      if (paymentDetail.pdfData && download) {
        myRef.current.click();
      }
    }, [paymentDetail]);

    const handlePdfDownload = (id) => {
      clearPaymentDetails();
      ViewPayment(id, `email=${user.Email_01}`);
      setDownload(true);
    };

    // function to toggle payment alert closing
    const togglePaymentAlert = () => {
      setOpenPaymentAlert(false);
    };

    // function to cancel recurring payment
    const handleCancelPayment = (item) => {
      cancelRecurringPayment(
        item.recordId,
        {
          subscriptionId: item["Lease_SUBC_active::subscriptionID"],
          customerProfileId: item["Lease_SUBC_active::customerProfileId"],
        },
        closeModal
      );
    };

    const handlePageClick = (e) =>{
      setPage(e.selected);
      GetAllPayment(onSuccess,e.selected);
    }

    return (
      <div>
        {openPaymentAlert ? (
          isFailed ? (
            <SweetAlert
              error
              confirmBtnText={t('sweetalert.okay')}
              confirmBtnBsStyle="error"
              cancelBtnBsStyle="default"
              title={t('sweetalert.failed')}
              onConfirm={() => togglePaymentAlert()}
            >
              {transactionResponse}
            </SweetAlert>
          ) : (
            <SweetAlert
              success
              confirmBtnText={t('sweetalert.okay')}
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="default"
              title={t('sweetlert.success')}
              onConfirm={() => togglePaymentAlert()}
            >
              Your payment has been processed.
              {/* {transactionResponse} */}
            </SweetAlert>
          )
        ) : (
          ""
        )}
        <div className="d-none">
          <a
            download={`Payment${"PAYMENTID"}_LEASE${"LEASEID"}_${moment().format(
              "MM/DD/YYYY_hh:mm A"
            )}`}
            href={"data:application/pdf;base64," + paymentDetail?.pdfData}
          >
            <i className="fa fa-download" ref={myRef} />
          </a>
        </div>

        {user &&
        user.leaseDetails && user?.leaseDetails?.EvictionStopBoolean ? (
          <div className="row mb-2">
            <div className="col-12">
              <Alert color="pink" className="m-b-10">
                  {t('dashboard.eviction_stop_alert')}
                 </Alert>
            </div>
          </div>
        ) : null}

        <Modal isOpen={modalDialog} toggle={() => toggleModal()}>
          <ModalHeader toggle={() => closeModal()}>
            {headerTitle || "Ania Management"}
          </ModalHeader>
          <ModalBody>
            {modalBody === "payment" && (
              <PaymentForm
                closeModal={closeModal}
                setIsCompleted={setIsCompleted}
                setIsFailed={setIsFailed}
                setOpenPaymentAlert={setOpenPaymentAlert}
                setTransactionResponse={setTransactionResponse}
              />
            )}

            {modalBody === "recurring_payment" && (
              <RecurringForm
                closeModal={closeModal}
                setTransactionResponse={setTransactionResponse}
                setIsFailed={setIsFailed}
                setOpenPaymentAlert={setOpenPaymentAlert}
              />
            )}

            {modalBody === "cancel_recurring_payment" && (
              <CancelRecurringList
                closeModal={closeModal}
                handleCancelPayment={handleCancelPayment}
              />
            )}
          </ModalBody>
        </Modal>
        <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="stats-title text-uppercase text-white font-weight-bold">
                  {t('card-title.payments')}
                </div>
                <div className="stats-desc">
                  {t('card-subtitle.payments')}
                  </div>
              </div>
              {
                    appLoading ? <i className="fas fa-spinner fa-pulse"></i> : null
                  }
              {
              user 
              && user.leaseDetails
              && user.leaseDetails["Lease_PROP::Address_Address01_finalDisplay"]
              && user.leaseDetails["Lease_PROP::Address_City"]
              && user.leaseDetails["Lease_PROP::Address_State"]
              && user.leaseDetails["Lease_PROP::Address_PostalCode"]
              && !user.leaseDetails.EvictionStopBoolean 
              && Leases 
              && Leases.length>0
              &&(
                <UncontrolledDropdown className="pull-right">
                  <DropdownToggle caret className="btn btn-warning">
                    {t('payment-card-button.make-payment')}
                  </DropdownToggle>
                  <DropdownMenu>
                    <>
                      <DropdownItem
                        onClick={() => {
                          if (!loading) {
                            toggleModal("payment");
                            setHeaderTitle(t('modal.payment.header'));
                          }
                        }}
                      >
                        {/* Credit Card Payment */}
                    {t('payment-card-button.credit-card')}
                      </DropdownItem>
                    </>
                    {/* <DropdownItem divider />
                    <DropdownItem
                      onClick={() => {
                        // if (!loading) {
                        //   if (!user?.leaseDetails?.subscription?.length) {
                        //     toggleModal("recurring_payment");
                        //     setHeaderTitle("Recurring Payment");
                        //   } else {
                        //     handleCancelPayment(
                        //       user?.leaseDetails?.subscription[0]
                        //     );
                        //   }
                        // }
                        if(!loading){
                          let subscriptions = user?.leaseDetails?.subscription
                          if(!subscriptions?.length){
                            toggleModal('recurring_payment');
                            setHeaderTitle(t('modal.payment.header-recurr'));
                          }else {
                            subscriptions?.length > 1 ? ((()=>{
                              toggleModal('cancel_recurring_payment');
                              setHeaderTitle(t('modal.payment.header-recurrCancel'));
                            })())
                            : handleCancelPayment(subscriptions[0]);
                          }
                        }
                      }
                    }
                    >
                      {!user?.leaseDetails?.subscription?.length
                        ? `${t('payment-card-button.auto-bill')}` : `${t('payment-card-button.cancel')}`
                      }
                    </DropdownItem> */}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </div>
          </PanelHeader>
          <PanelBody>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-nowrap">{t('card-table.payment.date')}</th>
                    <th className="text-nowrap">{t('card-table.payment.amount')}</th>
                    <th className="text-nowrap">{t('card-table.payment.status')}</th>
                    <th className="text-nowrap">{t('card-table.payment.description')}</th>
                    <th className="text-nowrap">{t('card-table.payment.receipt')}</th>
                  </tr>
                </thead>
                <tbody>
                  {payments &&
                    payments.map((item, i) => (
                      <tr
                        key={i}
                        className={`text-nowrap ${
                          !item["Lease_PAYM_NonVoided::isApproved"] &&
                          "text-muted font-italic"
                        }`}
                      >
                        <td>
                          {item["Lease_PAYM_NonVoided::zzCreatedDate"] || "-"}
                        </td>
                        <td>
                          ${" "}
                          {item["Lease_PAYM_NonVoided::zzc_Tenders_total"]
                            ? parseInt(
                                item["Lease_PAYM_NonVoided::zzc_Tenders_total"]
                              ).toFixed(2)
                            : "-"}
                        </td>
                        <td>
                          {item["Lease_PAYM_NonVoided::isApproved"]
                            ? "Approved"
                            : "Pending" || "-"}
                        </td>
                        <td>{item["Lease_PAYM_NonVoided::Note"] || "-"}</td>
                        <td>

                          {
                           item["Lease_PAYM_NonVoided::isApproved"] ? (
                              <button
                                className={`btn btn-info btn-xs ${
                                  !item["Lease_PAYM_NonVoided::isApproved"] &&
                                  "hover-not"
                                }`}
                                onClick={(e) => handlePdfDownload(item.recordId)}
                              >
                                <i className="fa fa-download" /> PDF
                              </button>
                            ): null
                          }

                          {/* {
                           !item["Lease_PAYM_NonVoided::isApproved"] && !item["Lease_PAYM_NonVoided::isSettled"] && !item["Lease_PAYM_NonVoided::isWebpayment"] ? (
                              <button
                                className={`btn btn-info btn-xs`}
                                onClick={(e)=>{}}
                              >
                                Cancel
                              </button>
                            ): null
                          } */}

                          {/* {item["Lease_PAYM_NonVoided::isApproved"] ? (
                            <button
                              className={`btn btn-info btn-xs ${
                                !item["Lease_PAYM_NonVoided::isApproved"] &&
                                "hover-not"
                              }`}
                              onClick={(e) => handlePdfDownload(item.recordId)}
                            >
                              <i className="fa fa-download" /> PDF
                            </button>
                          ) : (
                            "-"
                          )} */}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </PanelBody>
        </Panel>
        {
        pageCount ?
      
      <ReactPaginate
          previousLabel={<i className="fas fa-angle-double-left" />}
          nextLabel={<i className="fas fa-angle-double-right" />}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />:null}
      </div>
    );
  }
);

const mapStateToProps = ({
  USER_REDUCER,
  PAYMENT_REDUCER,
  LOADING_REDUCER
}) => {
  return {
    payments: PAYMENT_REDUCER.payments,
    user: USER_REDUCER.userData,
    paymentDetail: PAYMENT_REDUCER.paymentDetail,
    Leases: USER_REDUCER.AllLeases,
    appLoading: LOADING_REDUCER.appLoading
  };
};

export default connect(mapStateToProps, {
  ViewPayment,
  clearPaymentDetails,
  cancelRecurringPayment,
  GetAllPayment
})(PaymentsList);
